
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Input, Label, Table } from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { BrandsGameList, providerGameList, Game, providerBlockGames, providerBlockBrands, filteredBrandsProvider } from "Services/Api";
import "../OperatorTenant/Addoperator.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Skeleton, { SkeletonTheme, } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
interface Brand {
    brand_name: string;
    id: number;
}
const EditOperator = () => {
    const { t } = useTranslation();
    const [animationNavTab, setanimationNavTab] = useState<string>("1");
    const [brandOptions, setBrandOptions] = useState<any[]>([]);
    const [brandName, setBrandName] = useState<any[]>([]);
    const [providerGame, setProviderGame] = useState<Game[]>([])
    const [/*providerBrands*/, setProviderBrands] = useState<any[]>([]);
    const [savedBrandsId, setSavedBrandsId] = useState<any[]>([]);
    const [savedGamesId, setSavedGamesId] = useState<any[]>([]);
    const [/*brandsActivity*/, setBrandsActivity] = useState<boolean>(false);
    const [/*gamesActivity*/, setGamesActivity] = useState<boolean>(false);
    const [/*checkedProviderGames*/, setCheckedProviderGames] = useState<any[]>([]);
    const [checkedproviderGamesBlock, /*setCheckedproviderGamesBlock*/] = useState<number[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<{ label: string; value: string }[] | null>([]);
    var [filteredGames, setFilteredGames] = useState<Game[]>([]);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [searchInput, setSearchInput] = useState('');

    const colors = ['secondary', 'success', 'warning', 'danger', 'info', 'dark']; //checkbox colors
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const providerId: any = searchParams.get('vendor_id');
    const vendorName: any = searchParams.get('name');
    // const data = location.state ? location.state.data : null;
    const [ids, setIds] = useState<any[]>([]);
    document.title = `Edit ${vendorName}`;



    useEffect(() => {
        if (providerId) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [providerId]);

    const fetchData = async () => {
        try {
            const requestData = { vendor_id: providerId };
            const response = await BrandsGameList(requestData);
            const brands: Brand[] = response.data && response.data.brands ? response.data.brands : [];
            const options = brands.map(brand => ({
                label: brand.brand_name,
                value: brand.id
            }));
            setBrandName(options)

            setBrandOptions(brands);

            const filteredData = brands.filter((item: any) => item.block_all_operators === 1);
            const alreadysetBrands = filteredData.map((item: any) => item.id);
            setSavedBrandsId(alreadysetBrands);

        } catch (error) {
            console.error('Error fetching brand list:', error);
            if (error === 'Request failed with status code 401') {
                navigate('/login');
                toast.error("Token expired. Please login again.")
            } else {

            }
        } finally {
            setLoading1(false)
        }
    };


    var filteredGames = providerGame.filter((game) =>
        game.name && game.name.toLowerCase().includes(searchInput.toLowerCase())
    );

    const handleCheckboxChangeBrands = (e: React.ChangeEvent<HTMLInputElement>, brandId: any) => {
        setBrandsActivity(true);
        setBrandOptions((prevBrandOptions) => prevBrandOptions.map((record) => {
            if (record.id === brandId) {
                const isChecked = e.target.checked;

                return { ...record, block_all_operators: isChecked ? 1 : 0 };
            }
            return record;
        }));

        setProviderBrands((prevProviderBrands) => {
            const isChecked = e.target.checked;
            if (isChecked) {
                const updatedBrandsId = [...savedBrandsId, brandId];
                setSavedBrandsId(updatedBrandsId);
            } else {

                const updatedBrandsId = savedBrandsId.filter((id) => id !== brandId);
                setSavedBrandsId(updatedBrandsId);
            }
            const updatedBrands = isChecked
                ? [...prevProviderBrands, brandId]
                : prevProviderBrands.filter((id) => id !== brandId);



            return updatedBrands;

        });
    };

    const handleGamesCheckbox = (e: React.ChangeEvent<HTMLInputElement>, gameId: any, target: string) => {
        if (target === "donotshow") {

            setGamesActivity(true)
            setProviderGame((prevGameOptions) => prevGameOptions.map((record) => {
                if (record.id === gameId) {
                    const isChecked = e.target.checked;

                    return { ...record, show_to_operator: isChecked ? 1 : 0 };
                }
                return record;
            }));
            setCheckedProviderGames((prevProviderBrands) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                    const updatedGamesId = [...ids, gameId];
                    setIds(updatedGamesId);
                } else {

                    const updatedGamesId = ids.filter((id) => id !== gameId);
                    setIds(updatedGamesId);
                }
                const updatedBrands = isChecked
                    ? [...prevProviderBrands, gameId]
                    : prevProviderBrands.filter((id) => id !== gameId);



                return updatedBrands;

            });
        } else {
            setGamesActivity(true)
            setProviderGame((prevGameOptions) => prevGameOptions.map((record) => {
                if (record.id === gameId) {
                    const isChecked = e.target.checked;

                    return { ...record, block_all_operators: isChecked ? 1 : 0 };
                }
                return record;
            }));
            setCheckedProviderGames((prevProviderBrands) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                    const updatedGamesId = [...savedGamesId, gameId];
                    setSavedGamesId(updatedGamesId);
                } else {

                    const updatedGamesId = savedGamesId.filter((id) => id !== gameId);
                    setSavedGamesId(updatedGamesId);
                }
                const updatedBrands = isChecked
                    ? [...prevProviderBrands, gameId]
                    : prevProviderBrands.filter((id) => id !== gameId);



                return updatedBrands;

            });
        }
    };

    const handleBlockBrands = async () => {
        try {
            if (!savedBrandsId || savedBrandsId.length === 0) {
                toast.error('Brand ID cannot be null or empty.');
                return;
            }
            const requestData = {
                vendor_id: providerId,
                brand_id: savedBrandsId,
            };
            setanimationNavTab("2")

            const response = await providerBlockBrands(requestData);



            if (response && response.status === 'success') {
                toast.success(response.message || 'Brands successfully!', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            } else {
                toast.error(response.message || 'Failed to Blocked Brands.');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Block Brands.');
            if (error === 'Request failed with status code 401') {
                navigate('/login');
                toast.error("Token expired. Please login again.")
            }
        }
    }
    const handleGamesList = async () => {
        try {
            //if (brandsActivity === true) {
                var resData = { provider_ids: providerId, brands_ids: savedBrandsId };
            //} else {
              //  var resData = { provider_ids: providerId, brands_ids: savedBrandsId };
            //}
            const requestData = resData;
            const response = await providerGameList(requestData);


            if (response?.status === 'success') {
                const gameData: any[] = response.data.map((game: Game) => ({
                    id: game.id,
                    name: game.name,
                    brand: game.games_brands,
                    block_all_operators: game.block_all_operators,
                    show_games: game.show_to_operator

                }));

                setProviderGame(gameData || []);
                gameData.forEach((name) => {

                });

                const games = response.data
                const filteredData = games.filter((item: any) => item.block_all_operators === 1);
                const show_to_operator = games.filter((item: any) => item.show_to_operator === 1);
                setIds(show_to_operator.map((item: any) => item.id));
                const alreadysetGames = filteredData.map((item: any) => item.id);
                setSavedGamesId(alreadysetGames);

            } else {

            };
        }

        catch (error) {
            console.error('Error:', error);
            if (error === 'Request failed with status code 401') {
                navigate('/login');
                toast.error("Token expired. Please login again.")
            } else {

            }


        } finally {
            setLoading2(false)
        }
    }

    const handleSingleSelectChange = async (selected: { label: string; value: string } | null) => {
        setSelectedOptions(selected ? [selected] : null);

        try {
            if (selected) {
                const brands_ids = selected.value;

                const response = await filteredBrandsProvider(brands_ids);
                if (response && response.data && response.status === 'success') {
                    const brandsData = response.data;

                    const gameData = brandsData.map((game: Game) => ({
                        id: game.id,
                        name: game.name,
                        brand: game.games_brands,
                        block_all_operators: game.block_all_operators,
                        show_games: game.show_to_operator
                    }));

                    setProviderGame(gameData || []);

                    const filteredGamess = gameData.filter((game: any) =>
                        game.name &&
                        game.name.toLowerCase().includes(searchInput.toLowerCase())
                    );

                    setFilteredGames(filteredGamess);
                } else {
                    toast.error('Failed to show brands.');
                }
            } else {
                setProviderGame([]);

                setFilteredGames([]);

            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to show brands.');
            if (error === 'Request failed with status code 401') {
                navigate('/login');
                toast.error("Token expired. Please login again.")
            }
        }

    };

    const handleBlockGames = async () => {
        try {
            const uniqueGamesId = [...new Set(savedGamesId)];

            if (!savedGamesId || savedGamesId.length === 0) {
                toast.error('Game ID cannot be null or empty.');
                return;
            }

            const requestData = {
                provider_ids: providerId,
                brands_ids: savedBrandsId,
                games_ids: uniqueGamesId,
                games_block_arr: checkedproviderGamesBlock,
                show_games: ids
            };

            const response = await providerBlockGames(requestData);

            if (response && response.status === 'success') {
                toast.success(response.message || 'Games Blocked  successfully!', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });

            } else {
                toast.error(response.message || 'Failed to Blocked Games.');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Blocked Games');
            if (error === 'Request failed with status code 401') {
                navigate('/login');
                toast.error("Token expired. Please login again.")
            }
        }
    }

    const animationNavToggle = (tab: any) => {
        if (animationNavTab !== tab) {
            setanimationNavTab(tab);
        }
    };
    return (
        <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={vendorName} pageTitle="Edit Provider" />

                        <div>
                            <Row className="align-items-center">
                                <Col lg={12}>
                                    <div className="d-flex justify-content-end align-item-center">

                                        <Link to="/Game-provider"><button className="btn btn-primary"> <i className=" ri-arrow-left-line"></i>{t('Back')}</button></Link>

                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="d-flex align-items-end justify-content-center mt-3">
                            <Col xs={12} md={12} lg={12} xxl={9} >
                                <Card xs={12} sm={12}>
                                    <CardBody xs={12} sm={12}>
                                        <Nav pills className="nav nav-pills animation-nav nav-justified gap-2 mb-3">
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: animationNavTab === "1", })} onClick={() => { animationNavToggle("1"); fetchData(); }} >
                                                    {t('Manage Brands')}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: animationNavTab === "2", })} onClick={() => { animationNavToggle("2"); handleGamesList() }} >
                                                    {t('Manage Games')}
                                                </NavLink>
                                            </NavItem>

                                        </Nav>


                                        <TabContent activeTab={animationNavTab} >
                                            <TabPane tabId="1" id="animation-home">
                                                <Row className="align-items-center border-dark mt-3">
                                                    <Col xs={12}>
                                                        <div className='table-responsive'>
                                                            <Table>
                                                                <thead>
                                                                    <tr className="text-center">
                                                                        <th>{t('Select The Brand To Block')} </th>
                                                                        <th>{t('Action')}</th>
                                                                        {/* <th >{t('Block for All Operators')}</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {loading1 ? (
                                                                        Array.from({ length: brandOptions.length || 8 }).map((_, index) => (
                                                                            <tr key={index}>
                                                                                <td><Skeleton height={30} /></td>
                                                                                <td><Skeleton height={30} /></td>
                                                                                {/* <td><Skeleton height={30} /></td> */}
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        brandOptions && brandOptions.length > 0 ? (
                                                                            brandOptions.map((brand: any, index) => (
                                                                                <tr key={brand.id} className="hover-effect">
                                                                                    <td>
                                                                                        <div className={`text-center form-check form-check-${colors[index % colors.length]}`} style={{ display: 'flex', justifyContent: 'right' }}>
                                                                                            <div className="manage-input-main">
                                                                                                <div className="manage-input">
                                                                                                    <Input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id={`brandCheckbox${brand.id}`}
                                                                                                        onChange={(e) => handleCheckboxChangeBrands(e, brand.id)}
                                                                                                        checked={brand.block_all_operators === 1}
                                                                                                    />
                                                                                                    &nbsp;&nbsp;
                                                                                                    <Label className="form-check-label" htmlFor={`brandCheckbox${brand.id}`}>
                                                                                                        {brand.brand_name}
                                                                                                    </Label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className={`text-center form-check form-check-${colors[index % colors.length]}`} style={{ display: 'flex', justifyContent: 'right' }}>
                                                                                            <div className="manage-input-main">
                                                                                                <div className="manage-input">
                                                                                                    <Input className="form-check-input" type="checkbox" id={`formCheck1${brand.id}`} />
                                                                                                    &nbsp;
                                                                                                    <Label className="form-check-label" htmlFor={`formCheck1${brand.id}`} >
                                                                                                        {t(' Select to Enable VPN')}
                                                                                                    </Label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    {/* <td>
                                                                                        <div className={`text-center form-check form-check-${colors[index % colors.length]}`}>
                                                                                            <Input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                id={`gameCheckBlock${index}`}
                                                                                                onChange={(e) => handleCheckboxChangeBrands(e, brand.id)}
                                                                                                checked={brand.block_all_operators === 1}
                                                                                            />
                                                                                            &nbsp;&nbsp;
                                                                                            <Label className="form-check-label" htmlFor={`gameCheckBlock${index}`} >
                                                                                                {t('Block for All Operators')}
                                                                                            </Label>
                                                                                        </div>
                                                                                    </td> */}
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan={3} className="text-center">
                                                                                    <strong>{t('No Data Available')}</strong>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                                </tbody>

                                                            </Table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="text-end mt-5">
                                                    {loading1 ? (
                                                        <Skeleton width={100} height={40} />
                                                    ) : (
                                                        <button className="btn btn-primary" onClick={() => { handleBlockBrands(); handleGamesList() }}>
                                                            {t('Save')}
                                                        </button>
                                                    )}
                                                </div>

                                            </TabPane>

                                            <TabPane tabId="2" id="animation-profile">
                                                <Row className="align-items-center border-dark mt-3">
                                                    <Col >
                                                        <Row>
                                                            <Col xs={10} sm={6} md={6} >
                                                                <div className="app-search d-flex justify-content-start">
                                                                    <div className="form-control mb-3 " style={{ width: "340px" }}>
                                                                        <Select
                                                                            value={selectedOptions}
                                                                            onChange={handleSingleSelectChange}
                                                                            options={brandName}
                                                                            placeholder={t("Select Brands")}
                                                                            isSearchable={true}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col xs={10} sm={4} md={6} >
                                                                <div className="app-search d-flex justify-content-end">
                                                                    <div className="position-relative">
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control w-80"
                                                                            placeholder={t("Search Games")}
                                                                            value={searchInput}
                                                                            onChange={(e) => setSearchInput(e.target.value)}
                                                                        />
                                                                        <span className="mdi mdi-magnify search-widget-icon"></span>
                                                                        <span
                                                                            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                                                            id="search-close-options"
                                                                        ></span>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <div className='table-responsive'>

                                                            <Table>
                                                                <thead>
                                                                    <tr className="text-center">
                                                                        <th style={{whiteSpace:'nowrap'}}>{t('Select The Game To Block')}</th>
                                                                        <th>{t('Brands')}</th>
                                                                        <th style={{whiteSpace:'nowrap'}}>{t('Do not show on Operator')}</th>
                                                                        {/* <th style={{whiteSpace:'nowrap'}}>{t('Block for All Operators')}</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {loading2 ? (
                                                                        Array.from({ length: filteredGames.length || 10 }).map((_, index) => (
                                                                            <tr key={`skeletonRow${index}`}>
                                                                                <td>
                                                                                    <Skeleton height={30} />
                                                                                </td>
                                                                                <td>
                                                                                    <Skeleton height={30} />
                                                                                </td>
                                                                                <td>
                                                                                    <Skeleton height={30} />
                                                                                </td>
                                                                                {/* <td>
                                                                                    <Skeleton height={30} />
                                                                                </td> */}
                                                                            </tr>
                                                                        ))
                                                                    ) : filteredGames.length === 0 ? (
                                                                        <tr>
                                                                            <td colSpan={4} className="text-center">
                                                                                <strong>{t('No Data Available')}</strong>
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        filteredGames.map((game, index) => (
                                                                            <tr key={`gameCheckbox${index}`} className="hover-effect ">
                                                                                <td>
                                                                                    <div className={`text2 form-check form-check-${colors[index % colors.length]}`}>
                                                                                        <div className="manage-input-main">
                                                                                            <Input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                id={`gameCheckbox${index}`}
                                                                                                onChange={(e) => handleGamesCheckbox(e, game.id, "eee")}
                                                                                                checked={game.block_all_operators === 1}
                                                                                            />
                                                                                            &nbsp;&nbsp;
                                                                                            <Label className="form-check-label" htmlFor={`gameCheckbox${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                <span>{game.name}</span>
                                                                                            </Label>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className={`text-center form-check form-check-${colors[index % colors.length]}`}>
                                                                                        <Label>
                                                                                            <span>{game.brand}</span>
                                                                                        </Label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className={`text-center form-check form-check-${colors[index % colors.length]}`}>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            id={`formCheck1${game.id}`}
                                                                                            onChange={(e) => handleGamesCheckbox(e, game.id, "donotshow")}
                                                                                            checked={ids.includes(game.id)}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                {/* <td>
                                                                                    <div className={`text-center form-check form-check-${colors[index % colors.length]}`}>
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            id={`formCheck1${game.id}`}
                                                                                            onChange={(e) => handleGamesCheckbox(e, game.id, "eee")}
                                                                                            checked={game.block_all_operators === 1}
                                                                                        />
                                                                                        &nbsp;&nbsp;
                                                                                        <Label className="form-check-label" htmlFor={`formCheck1${game.id}`} >
                                                                                            {t('Block for All Operators')}
                                                                                        </Label>
                                                                                    </div>
                                                                                </td> */}
                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="text-end mt-5">
                                                    {loading2 ? (
                                                        <Skeleton width={100} height={40} />
                                                    ) : (
                                                        <button className="btn btn-primary" onClick={() => { handleBlockGames() }}>
                                                            {t('Save')}
                                                        </button>
                                                    )}

                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        </SkeletonTheme>
    );
};

export default EditOperator;
