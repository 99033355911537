import React, { useState, useEffect, useCallback } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Row,
  Input,
  Alert,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import classnames from "classnames";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import "../OperatorTenant/Addoperator.css";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import Swal from "sweetalert2";
import {
  fetchCurrencylist,
  fetchVpnUrl,
  Currency,
  resetAdvConfig,
  getVendorsWithConfigSingle,
  getVendorsWithConfig,
  fetchAdvConfigData,
  fetchGameProvider,
  fetchItemDetails,
  editGgrandNgr,
  HistoryRecord,
  addOperator,
  addOperatorSubscription,
  IpWhitelist,
  Iplist,
  Provider,
  addOrUpdateAdmin,
  getBearerToken,
  FormData,
  getListTenantAdmin,
  TenantAdmin,
  updateTenantAdminStatus,
  generateToken,
  Vendor,
  BrandsGameList,
  addBrands,
  GameList,
  Game,
  addGames,
  saveAdvanceConfig,
  addGgr,
  filteredBrands,
  addCredit,
  creditlist,
  removeCredit,
  GGRandNGRHistory,
  getDatabases,
  addDatabase,
} from "Services/Api";
// import Select from 'react-select';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { PermissionHelper } from "Routes/helper";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import Select from "react-select";
import { t } from "i18next";

interface CreateDatabaseModalProps {
  isOpen: boolean;
  toggle: () => void;
  dbHost: string;
  setDbHost: (value: string) => void;
  dbUser: string;
  setDbUser: (value: string) => void;
  dbPassword: string;
  setDbPassword: (value: string) => void;
  dbName: string;
  setDbName: (value: string) => void;
  dbPort: string;
  setDbPort: (value: string) => void;
  handleCreate: () => void; // Define the type for handleCreate
}
type SelectedCurrencyType = string | null;

const CreateDatabaseModal: React.FC<CreateDatabaseModalProps> = ({
  isOpen,
  toggle,
  dbHost,
  setDbHost,
  dbUser,
  setDbUser,
  dbPassword,
  setDbPassword,
  dbName,
  setDbName,
  dbPort,
  setDbPort,
  handleCreate,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">{t("Create New Database")}</h5>
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <div className="modal-body">
        <Row className="mb-3 justify-content-start">
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="dbHostInput">{t("Database Host")}</Label>
          </Col>
          <Col lg={7}>
            <Input
              type="text"
              id="dbHostInput"
              value={dbHost}
              onChange={(e) => setDbHost(e.target.value)}
              placeholder={t("Database Host")}
            />
          </Col>
        </Row>
        <Row className="mb-3 justify-content-start">
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="dbUserInput">{t("Database User")}</Label>
          </Col>
          <Col lg={7}>
            <Input
              type="text"
              id="dbUserInput"
              value={dbUser}
              onChange={(e) => setDbUser(e.target.value)}
              placeholder={t("Database User")}
            />
          </Col>
        </Row>
        <Row className="mb-3 justify-content-start">
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="dbPasswordInput">{t("Database Password")}</Label>
          </Col>
          <Col lg={7}>
            <Input
              type="password"
              id="dbPasswordInput"
              value={dbPassword}
              onChange={(e) => setDbPassword(e.target.value)}
              placeholder={t("Database Password")}
            />
          </Col>
        </Row>
        <Row className="mb-3 justify-content-start">
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="dbNameInput">{t("Database Name")}</Label>
          </Col>
          <Col lg={7}>
            <Input
              type="text"
              id="dbNameInput"
              value={dbName}
              onChange={(e) => setDbName(e.target.value)}
              placeholder={t("Database Name")}
            />
          </Col>
        </Row>
        <Row className="mb-3 justify-content-start">
          <Col lg={4} className="d-flex align-items-center">
            <Label htmlFor="dbPortInput">{t("Database Port")}</Label>
          </Col>
          <Col lg={7}>
            <Input
              type="text"
              id="dbPortInput"
              value={dbPort}
              onChange={(e) => setDbPort(e.target.value)}
              placeholder={t("Database Port")}
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleCreate}>
          {t("Create")}
        </Button>
        <Button color="secondary" onClick={toggle}>
          {t("Cancel")}
        </Button>
      </div>
    </Modal>
  );
};

const AddOperator = () => {
  const { t } = useTranslation();
  const [remainingCredit, setRemainingCredit] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loadingCheckboxes, setLoadingCheckboxes] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading5, setLoading5] = useState(true);
  const [loading6, setLoading6] = useState(true);
  const [loading7, setLoading7] = useState(true);
  const [, /*loading8*/ setLoading8] = useState(true);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [animationNavTab, setanimationNavTab] = useState<string>("1");
  const [modal_varying1, setmodal_varying1] = useState<boolean>(false);
  const [modal_viewconfig, setmodal_viewconfig] = useState<boolean>(false);
  const [modal_editconfig, setmodal_editconfig] = useState<boolean>(false);
  const [modal_varying2, setmodal_varying2] = useState<boolean>(false);
  const [operatorName, setOperatorName] = useState<string>("");
  const [operatorDomain, setOperatorDomain] = useState<string>("");
  const [callbackUrl, setCallbackUrl] = useState<string>("");
  const [ggr /*setGgr*/] = useState<any>([]);
  const [credit, setCredit] = useState<any>("");
  const [betLimit, setBetLimit] = useState<any>("");
  const [creditData, setCreditData] = useState<any>([]);
  const [advaConfigData, SetadvaConfigData] = useState<any>([]);
  const [singleAdvaConfigData, SetsingleAdvaConfigData] = useState<any>([]);
  const [operatorId, setOperatorId] = useState<number>(0);
  const [searchBrandInput, setSearchBrandInput] = useState("");
  const [id, setId] = useState<any>();
  const [idNew, setIdNew] = useState<any>();
  const [provider, setProvider] = useState<Provider[]>([]);
  const colors = ["secondary", "success", "warning", "danger", "info", "dark"]; //checkbox colors
  const [checkedProviders, setCheckedProviders] = useState<number[]>([]);
  const [checkboxValues, setCheckboxValues] = useState<number[]>([]);
  const [checkedbBrandsVpn, setCheckedbBrandsVpn] = useState<number[]>([]);
  const [checkedbGamesBlock, setCheckedGamesBlock] = useState<number[]>([]);
  const [checkedBrandsBlock, setCheckedBrandsBlock] = useState<number[]>([]);
  const [ipValue, setIpValue] = useState<any>("");
  const [ipList, setIpList] = useState<any>("");
  const [tenantAdminList, setTenantAdminList] = useState<TenantAdmin[]>([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isNewAdminClicked, setIsNewAdminClicked] = useState(false);
  const [isAddAdminUser, setIsAddAdminUser] = useState(true);
  const [, /*vendors*/ setVendors] = useState<Vendor[]>([]);
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    makeOwnerActive: false,
    kycVerified: false,
  });
  const [token, setToken] = useState<any>();
  const [tokenExpDate, setTokenExpDate] = useState<any>();
  const [games, setGames] = useState<Game[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [checkedBrands, setCheckedBrands] = useState<any[]>([]);
  const [checkedGames, setCheckedGames] = useState<any[]>([]);
  const [checkedAllGames, setCheckedAllGames] = useState<any[]>([]);
  const [checkedAllBrands, setCheckedAllBrands] = useState<any[]>([]);
  const defaultSelectedCurrency: SelectedCurrencyType = "EUR";
  const [selectedCurrency, setSelectedCurrency] =
    useState<SelectedCurrencyType>(defaultSelectedCurrency);
  const [modal_standard, setModal_standard] = useState(false);
  const [selectedDates, setSelectedDates] = useState(new Date());
  const [ggrValues, setGgrValues] = useState<{ [key: number]: string }>({});
  const [ngrValues, setNgrValues] = useState<{ [key: number]: string }>({});
  const [editGgrValues, setEditGgrValues] = useState<{ [key: number]: string }>(
    {}
  );
  const [editNgrValues, setEditNgrValues] = useState<{ [key: number]: string }>(
    {}
  );
  const [modal, setModal] = useState(false);
  const [currentBrandId, setCurrentBrandId] = useState<any | null>(null);
  const [historyData, setHistoryData] = useState<HistoryRecord[]>([]);
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tenentID: any = searchParams.get("id");
  const [showKeys /*setShowKeys*/] = useState(false);
  const [selectedRow /*setSelectedRow*/] = useState(null); // State to track selected row
  const [opeIdEditConfig, setOpeIdEditConfig] = useState<number>();
  const [vendorIdEditConfig, setVendorIdEditConfig] = useState<number>();
  const [editSecretKeyValue, setEditSecretKeyValue] = useState<string>();
  const [editAPIKeyValue, setEditAPIKeyValue] = useState<string>();
  const [isDedicatedDb, setIsDedicatedDb] = useState(false); // Checkbox state
  const [dbHost, setDbHost] = useState("");
  const [dbUser, setDbUser] = useState("");
  const [dbPassword, setDbPassword] = useState("");
  const [dbName, setDbName] = useState("");
  const [dbPort, setDbPort] = useState("");
  const [selectedDatabase, setSelectedDatabase] = useState("");
  const [databases, setDatabases] = useState<any[]>([]);
  const [newDbs, setNewDbs] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [vpnDropdowns, setVpnDropdowns] = useState([]);
  const [selectedVpn, setSelectedVpn] = useState<{ [key: string]: string }>({});
  const [selectedVpnUrls, setSelectedVpnUrls] = useState<{ [key: number]: string }>({});
  const [isAddCreditButtonDisabled, setIsAddCreditButtonDisabled] = useState(false);
  const [isRemoveCreditButtonDisabled, setIsRemoveCreditButtonDisabled] = useState(false);

  // Handler function for the dropdown change
  const handleVpnChange = (brandId: number, selectedOption: { value: string; label: string }) => {
    if (selectedOption) {
      // Update the selectedVpnUrls state with the new VPN URL for the specific brand
      setSelectedVpnUrls((prevState) => ({
        ...prevState,
        [brandId]: selectedOption.value, // store the VPN URL
      }));
    }
  };
  
  const fetchDatabase = useCallback(async () => {
    try {
      const response = await getDatabases();
      setDatabases(response);
    } catch (error) {
      toast.error("something went wrong");
    }
  }, []);

  const fetchConfigData = useCallback(async () => {
    try {
      let operatorIdP: any;

      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      const apiResponse = await fetchAdvConfigData(operatorIdP);

      if (Array.isArray(apiResponse)) {
        //    SetadvaConfigData(apiResponse);
      } else {
        console.error("Invalid API response format:", apiResponse);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error === "Request failed with status code 401") {
        navigate("/login");
        toast.error("Token expired. Please login again.");
      }
    }
  }, [navigate, operatorId, tenentID]);

  const fetchDataAndSetState = async (response: any) => {
    // Handle Operator data

    if (
      response &&
      response.operatordata !== null &&
      response.operatordata !== undefined
    ) {
      setOperatorName(response.operatordata.operatorname || "");
      setOperatorDomain(response.operatordata.domain || "");
      setCallbackUrl(response.operatordata.callbackUrl);
      setBetLimit(response.operatordata.bet_limit);
      setRemainingCredit(response?.operatordata?.credit_limit_remain);
      setSelectedCurrency(response.operatordata.currencies);
      setLoading2(false);
      setLoadingCheckboxes(false);

      // Handle IP list

      if (Array.isArray(response.operatordata.white_list_ips)) {
        setIpList(response.operatordata.white_list_ips);
      } else if (typeof response.operatordata.white_list_ips === "string") {
        setIpList(
          response.operatordata.white_list_ips
            .split(",")
            .map((item: any) => item.trim())
        );
      } else {
        setIpList([]);
      }
      setTenantAdminList(response.admindata);
    }

    if (response && response.operator_db) {
      const operatorDb = response.operator_db;
      const isDbDedicated = operatorDb ? true : false;
      setIsDedicatedDb(isDbDedicated);
      if (isDbDedicated) {
        setSelectedDatabase(operatorDb.id);
      }
    }

    // Handle credit list

    if (
      response &&
      response.credit_records &&
      response.credit_records.length > 0
    ) {
      setCreditData(response.credit_records);
    }
    // Handle brands list

    if (response && response.brands && response.brands.length > 0) {
      const receivedBrands = response.brands;
      const brandIdsFromApiResponse = receivedBrands.map(
        (brand: any) => brand.brand_id
      );
      setCheckedBrands(brandIdsFromApiResponse);

      const vpnEnabledBrandIds = receivedBrands
        .filter((brand: any) => brand.is_vpn_enabled === 1)
        .map((brand: any) => brand.brand_id);
      setCheckedbBrandsVpn(vpnEnabledBrandIds);

      const blockForAllOperators = receivedBrands
        .filter((brand: any) => brand.block_all_operators === 1)
        .map((brand: any) => brand.id);
      setCheckedGamesBlock(blockForAllOperators);


    const vpnUrls: { [key: number]: string } = {};
    receivedBrands.forEach((brand: any) => {

      if (brand.vpn_url) {
        vpnUrls[brand.brand_id] = brand.vpn_url;
      }
    });

    setSelectedVpnUrls(vpnUrls);
    }
    

    // Handle Games list

    if (response && response.games && response.games.length > 0) {
      const receivedGames = response.games;
      const gameIdsFromApiResponse = receivedGames.map(
        (games: any) => games.id
      );
      setCheckedGames(gameIdsFromApiResponse);

      const blockForAllOperators = receivedGames
        .filter((game: any) => game.block_all_operators === 1)
        .map((game: any) => game.id);
      setCheckedGamesBlock(blockForAllOperators);
    }
    // Handle vendor or provider data

    if (
      response &&
      response.operatordata &&
      response.operatordata.vendor !== null &&
      response.operatordata.vendor !== undefined
    ) {
      const receivedVendors = response.operatordata.vendor;
      setVendors(receivedVendors);

      const filteredVendorIds = receivedVendors
        .filter((vendor: any) => vendor.vendor_status === 1)
        .map((vendor: any) => vendor.id);
      setCheckedProviders(filteredVendorIds);

      setCheckboxValues(() =>
        receivedVendors.map((vendor: any) =>
          vendor.pivot.is_vpn_enabled === 1 ? 1 : 0
        )
      );
    }
    // Handle ggr

    if (response && response.brands_gger && response.brands_gger.length > 0) {
      const ggrData = response.brands_gger.reduce((acc: any, brand: any) => {
        acc[brand.brand_id] = brand.ggr_percent;
        return acc;
      }, {});
      setGgrValues(ggrData);
    }
    if (response && response.brands_gger && response.brands_gger.length > 0) {
      const ggrData = response.brands_gger.reduce((acc: any, brand: any) => {
        acc[brand.brand_id] = brand.ngr_percent;
        return acc;
      }, {});
      setNgrValues(ggrData);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const apiResponse = await fetchGameProvider();
      const response = await fetchCurrencylist();
      setCurrencies(
        response.data.map((currency: any) => ({
          label: currency.code + " ( " + currency.name + " )",
          value: currency.code,
        }))
      );
      if (Array.isArray(apiResponse)) {
        setProvider(apiResponse);
      } else {
        console.error("Invalid API response format:", apiResponse);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error === "Request failed with status code 401") {
        navigate("/login");
        toast.error("Token expired. Please login again.");
      } else {
      }
    } finally {
      setLoading2(false);
    }
  }, [navigate]);
  useEffect(() => {
    fetchDatabase();
    fetchData();
    fetchConfigData();

    const operatordetails = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const idParam = queryParams.get("id");
        setLoading2(true);
        setLoadingCheckboxes(true);
        const response = await fetchItemDetails(`${idParam}`);
        setCredit(response?.operatordata?.credit_limit);
        fetchDataAndSetState(response);
        setLoading1(false);
      } catch (error) {
        console.error("Error fetching item details:", error);
        if (error === "Request failed with status code 401") {
          navigate("/login");
          toast.error("Token expired. Please login again.");
        } else {
        }
      } finally {
        setLoading2(false);
        setLoadingCheckboxes(false);
      }
    };
    const params = new URLSearchParams(location.search);
    const tabIndex = params.get("tab");
    if (tabIndex !== null) {
      setanimationNavTab("2");
    }

    operatordetails();
  }, [
    location.search,
    fetchDatabase,
    fetchData,
    fetchConfigData,
    navigate,
    selectedDatabase,
  ]);

  useEffect(() => {
    if (databases) {
      setNewDbs(
        databases.map((n) => ({
          label: n.db_name,
          value: n.id,
        }))
      );
    }
  }, [databases]);

  // const toggleKeysVisibility = () => {
  //   setShowKeys(!showKeys); // Toggle the state
  // };

  // const handleKeyChange = (providerId: any, keyType: any, keyValue: any) => {
  //   const updatedApiKeys = apiKeys.map(item => {
  //     if (item.providerId === providerId) {
  //       return { ...item, [keyType]: keyValue };
  //     }
  //     return item;
  //   });

  //   const existingIndex = updatedApiKeys.findIndex(item => item.providerId === providerId);
  //   if (existingIndex === -1) {
  //     updatedApiKeys.push({ providerId, [keyType]: keyValue });
  //   }

  //   setApiKeys(updatedApiKeys);
  // };

  // const handleApiKeyValueChange = (providerId: any, apiKeyValue: any) => {
  //   handleKeyChange(providerId, 'apiKey', apiKeyValue);
  // };

  // const handleApiSecretValueChange = (providerId: any, apiSecretValue: any) => {
  //   handleKeyChange(providerId, 'secretKey', apiSecretValue);
  // };

  // const fetchConfigData = async () => {
  //   try {
  //     let operatorIdP: any;

  //     if (typeof tenentID !== 'undefined' && tenentID !== null) {
  //       operatorIdP = tenentID;
  //     } else {
  //       operatorIdP = operatorId;
  //     }
  //     const apiResponse = await fetchAdvConfigData(operatorIdP);

  //     if (Array.isArray(apiResponse)) {
  //       //    SetadvaConfigData(apiResponse);

  //     } else {
  //       console.error('Invalid API response format:', apiResponse);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     if (error === 'Request failed with status code 401') {
  //       navigate('/login');
  //       toast.error("Token expired. Please login again.")

  //     }

  //   }
  // };
  // const fetchData = async () => {
  //   try {
  //     const apiResponse = await fetchGameProvider();
  //     const response = await fetchCurrencylist();
  //     setCurrencies(response.data.map((currency: any) => ({
  //       label: currency.code + ' ( ' + currency.name + ' )',
  //       value: currency.code
  //     })))
  //     if (Array.isArray(apiResponse)) {
  //       setProvider(apiResponse);

  //     } else {
  //       console.error('Invalid API response format:', apiResponse);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     if (error === 'Request failed with status code 401') {
  //       navigate('/login');
  //       toast.error("Token expired. Please login again.")

  //     } else {
  //     }

  //   } finally {
  //     setLoading2(false);
  //   }
  // };

  // Memoize the fetch functions to avoid unnecessary re-renders

  const getStatusText = (status_id: string) => {
    return status_id === "0" ? "InActive" : "Active";
  };
  const getStatusColor = (status_id: string) => {
    return status_id === "0" ? "badge bg-danger" : "badge bg-success";
  };
  const toggleStatus = async (id: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success ",
        cancelButton: "btn btn-danger me-2",
      },
      buttonsStyling: false,
    });

    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You want to change status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change status!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const updatedTenant = tenantAdminList.map((admin: any) => {
          if (admin.id === id) {
            return {
              ...admin,
              tenant_status: admin.status_id === "0" ? "1" : "0",
            };
          }
          return admin;
        });

        setTenantAdminList(updatedTenant);

        const updatedStatus = updatedTenant.find(
          (admin: any) => admin.id === id
        )?.tenant_status;

        if (updatedStatus !== undefined) {
          const response = await updateTenantAdminStatus(id, updatedStatus);
          if (response?.success === true) {
            tenantamdinList();
          }

          await Swal.fire({
            title: "Status Changed!",
            text: "Provider status has been updated.",
            icon: "success",
          });
        } else {
          throw new Error("Failed to find updated status");
        }
      } catch (error) {
        console.error("Error updating status:", error);
        await Swal.fire({
          title: "Error!",
          text: "Failed to update provider status.",
          icon: "error",
        });
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      await Swal.fire({
        title: "Cancelled",
        text: "Cancelled.",
        icon: "error",
      });
    }
  };

  const resetAdvanceConfig = async (vender_id: number, operator_id: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success ",
        cancelButton: "btn btn-danger me-2",
      },
      buttonsStyling: false,
    });

    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You want to reset the keys?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change Keys!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const updatedStatus = 1;

        if (updatedStatus !== undefined) {
          const response = await resetAdvConfig(vender_id, operator_id);
          if (response.status === "success") {
            advanceConfig();
          }

          await Swal.fire({
            title: "Reset Keys!",
            text: "Keys has been reset.",
            icon: "success",
          });
        } else {
          throw new Error("Failed to find updated reset");
        }
      } catch (error) {
        console.error("Error updating status:", error);
        await Swal.fire({
          title: "Error!",
          text: "Failed to update provider status.",
          icon: "error",
        });
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      await Swal.fire({
        title: "Cancelled",
        text: "Cancelled.",
        icon: "error",
      });
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]:
        name === "phoneNumber"
          ? type === "tel"
            ? value
            : Number(value)
          : value,
    }));
  };
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    providerId: number
  ) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setCheckedProviders([...checkedProviders, providerId]);
    } else {
      const updatedProviders = checkedProviders.filter(
        (id) => id !== providerId
      );
      setCheckedProviders(updatedProviders);
    }
  };
  const handleCheckboxChangeVPN = (index: number) => {
    setCheckboxValues((prevCheckboxValues) => {
      const updatedValues = [...prevCheckboxValues];
      updatedValues[index] = updatedValues[index] === 1 ? 0 : 1;
      return updatedValues;
    });
  };
  const handleCheckboxChangeBrands = (
    e: React.ChangeEvent<HTMLInputElement>,
    brandId: any
  ) => {
    const isChecked = e.target.checked;
    setCheckedBrands((prevCheckedBrands) => {
      const updatedBrands = isChecked
        ? [...prevCheckedBrands, brandId]
        : prevCheckedBrands.filter((id) => id !== brandId);

      return updatedBrands;
    });
  };
  const handleVpnCheckboxBrands = async (
    e: React.ChangeEvent<HTMLInputElement>,
    brandId: number
  ) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      const operator_id = operatorIdP;
      const vpnOptions = await fetchVpnOptions(operator_id);
      setVpnDropdowns(vpnOptions);
      //   setVpnDropdowns((prev) => ({
      //     ...prev,
      //     [brandId]: vpnOptions, // Save options for the specific brand
      //   }));
      // } else {
      //   setVpnDropdowns((prev) => {
      //     const newDropdowns = { ...prev };
      //     delete newDropdowns[brandId];
      //     return newDropdowns;
      //   });
    }
    setCheckedbBrandsVpn((prevCheckedbBrandsVpn) =>
      isChecked
        ? [...prevCheckedbBrandsVpn, brandId]
        : prevCheckedbBrandsVpn.filter((id) => id !== brandId)
    );
  };

  const handleCheckboxBlockBrands = (
    e: React.ChangeEvent<HTMLInputElement>,
    brandId: any
  ) => {
    const isChecked = e.target.checked;

    setCheckedBrandsBlock((prevCheckedBrandBlock) => {
      const updatedBrandBlock = isChecked
        ? [...prevCheckedBrandBlock, brandId]
        : prevCheckedBrandBlock.filter((id) => id !== brandId);

      return updatedBrandBlock;
    });
  };
  const handleGamesCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    gameIndex: number
  ) => {
    const isChecked = e.target.checked;

    setCheckedGames((prevCheckedGames) => {
      const updatedGames = isChecked
        ? [...prevCheckedGames, gameIndex]
        : prevCheckedGames.filter((checkedGame) => checkedGame !== gameIndex);

      return updatedGames;
    });
  };
  const handleCheckboxBlockGame = (
    e: React.ChangeEvent<HTMLInputElement>,
    gameId: any
  ) => {
    const isChecked = e.target.checked;

    setCheckedGamesBlock((prevCheckedGamesBlock) => {
      const updatedGamesBlock = isChecked
        ? [...prevCheckedGamesBlock, gameId]
        : prevCheckedGamesBlock.filter((id) => id !== gameId);

      return updatedGamesBlock;
    });
  };
  const handleSelectAllGames = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked: any = e.target.checked;
    setCheckedAllGames(isChecked);
    if (isChecked) {
      const allGameIds = games.map((game) =>
        typeof game === "object" ? game.id : null
      );
      setCheckedGames(allGameIds.filter((id) => id !== null));
    } else {
      setCheckedGames([]);
    }
  };
  const handleSelectAllBrands = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked: any = e.target.checked;
    setCheckedAllBrands(isChecked);
    if (isChecked) {
      const allBrandIds = brands.map((brand) =>
        typeof brand === "object" ? brand.id : null
      );
      setCheckedBrands(allBrandIds.filter((id) => id !== null));
    } else {
      setCheckedBrands([]);
    }
  };
  const handleGgrChange = (brandId: number, value: string) => {
    setGgrValues((prevValues) => ({
      ...prevValues,
      [brandId]: value,
    }));
  };
  const handleNgrChange = (brandId: number, value: string) => {
    setNgrValues((prevValues) => ({
      ...prevValues,
      [brandId]: value,
    }));
  };
  const handleEditGgrChange = (brandId: number, value: any) => {
    setEditGgrValues((prevValues) => ({
      ...prevValues,
      [brandId]: value,
    }));
  };
  const handleEditNgrChange = (brandId: number, value: any) => {
    setEditNgrValues((prevValues) => ({
      ...prevValues,
      [brandId]: value,
    }));
  };

  const handleAddOperator = async () => {
    try {
      if (
        !operatorName ||
        !operatorDomain ||
        betLimit === "" ||
        !selectedCurrency
      ) {
        toast.warning("Please fill in all fields");
        return;
      }

      const response = await addOperator(
        operatorName,
        operatorDomain,
        callbackUrl,
        tenentID,
        ggr,
        betLimit,
        selectedCurrency,
        isDedicatedDb,
        isDedicatedDb ? dbHost : undefined,
        isDedicatedDb ? dbUser : undefined,
        isDedicatedDb ? dbPassword : undefined,
        isDedicatedDb ? dbName : undefined,
        selectedDatabase
      );

      if (response?.data?.id !== "undefined") {
        setOperatorId(response.data.id);

        let currentUrl = window.location.href;

        let newSearch = "";
        if (currentUrl.includes("?")) {
          newSearch = "";
        } else {
          // newSearch = `${currentUrl}/?id=` + response.data.id;
          navigate(`/add-operator/?id=${response.data.id}`);
        }

        window.history.replaceState({}, document.title, newSearch);
      }
      toast.success(response.message || "Operator Added Successfully!", {
        autoClose: 2000,
      });
    } catch (error) {
      console.error("Error adding operator:", error);
      toast.warning("Something Went Wrong!");
    }
  };

  const handleAddCredits = async () => {
    try {
      if (!credit) {
        toast.warning("Please fill in all fields");
        return;
      }
      setIsAddCreditButtonDisabled(true);
      const storedResponse = sessionStorage.getItem("authUser");

      if (!storedResponse) {
        console.error("Response data not found ");
        setIsAddCreditButtonDisabled(false);
        return;
      }
      const parsedResponse = JSON.parse(storedResponse);

      const { username } = parsedResponse.data;

      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      const response = await addCredit(operatorIdP, credit, username);
      if (response.status === "error") {
        toast.error(response.errors, {
          autoClose: 2000,
        });
        setIsAddCreditButtonDisabled(false);
        return false;
      }
      const queryParams = new URLSearchParams(location.search);
      const idParam = queryParams.get("id");
      const response1 = await fetchItemDetails(`${idParam}`);
      setRemainingCredit(response1?.operatordata?.credit_limit_remain);
      toast.success(response.message || "Credit Added Successfully!", {
        autoClose: 2000,
      });

      creditlists();

      setCredit("");
    } catch (error) {
      console.error("Error adding operator:", error);
      toast.warning("Something Went Wrong!");
      setIsAddCreditButtonDisabled(false);
    }
    finally {
      setIsAddCreditButtonDisabled(false);
    }
  };

  const handleRemoveCredits = async () => {
    try {
      if (!credit) {
        toast.warning("Please fill in all fields");
        return;
      }
      if (remainingCredit < credit) {
        toast.warning("Credit should be less than remaining credit");
        return;
      }
      setIsRemoveCreditButtonDisabled(true);
      const storedResponse = sessionStorage.getItem("authUser");

      if (!storedResponse) {
        console.error("Response data not found ");
        setIsRemoveCreditButtonDisabled(false);
        return;
      }
      const parsedResponse = JSON.parse(storedResponse);

      const { username } = parsedResponse.data;

      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      const response = await removeCredit(operatorIdP, credit, username);
      if (response.status === "error") {
        toast.error(response.errors, {
          autoClose: 2000,
        });
        setIsRemoveCreditButtonDisabled(false);
        return false;
      }
      const queryParams = new URLSearchParams(location.search);
      const idParam = queryParams.get("id");
      const response1 = await fetchItemDetails(`${idParam}`);
      setRemainingCredit(response1?.operatordata?.credit_limit_remain);
      toast.success(response.message || "Credit Removed Successfully!", {
        autoClose: 2000,
      });

      creditlists();

      setCredit("");
    } catch (error) {
      console.error("Error adding operator:", error);
      toast.warning("Something Went Wrong!");
      setIsRemoveCreditButtonDisabled(false);
    }
    finally {
      setIsRemoveCreditButtonDisabled(false);
    }
  };

  const handleAddProviderToOpr = async () => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }

      const vpnConfigArray = checkedProviders.map((provider, index) => {
        const vpnConfig = {
          game_provider_id: provider,
          vpn_enable: checkboxValues[index],
        };

        return vpnConfig;
      });

      const requestData = {
        operator_id: operatorIdP,
        vendor_ids: checkedProviders,
        vpn_config: vpnConfigArray,
      };

      const response = await addOperatorSubscription(requestData);
      if (response.status === "error") {
        toast.error(response.errors, {
          autoClose: 2000,
        });
        return false;
      }

      if (response && response.status === "success") {
        toast.success(response.message || "Vendors subscribed successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setanimationNavTab("3");
      } else {
        toast.error(response.errors || "Failed to add providers.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add providers.");
    }
  };

  const handleBrandGamesList = async () => {
    try {
      setLoading3(true);
      const vendorId = checkedProviders.join(",");
      const requestData = { vendor_id: vendorId };
      if (vendorId) {
        const response = await BrandsGameList(requestData);

        if (response?.status === "success") {
          setBrands(response?.data?.brands || []);
        }
        if (response.data.brands) {
          const receivedBrands = response.data.brands;
          const blockForAllOperators = receivedBrands
            .filter((game: any) => game.block_all_operators === 1)
            .map((game: any) => game.id);
          setCheckedBrandsBlock(blockForAllOperators);
        } else {
          const errorMessage = response?.message || "Failed to show Games.";
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);

      if (error === "Request failed with status code 401") {
        navigate("/login");
        toast.error("Token expired. Please login again.");
      } else {
      }
    } finally {
      setLoading3(false);
    }
  };

  const fetchVpnOptions = async (operatorId: any) => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      const operator_id = operatorIdP
      const response = await fetchVpnUrl(operator_id);
      return response;
    } catch (error) {
      console.error("Error fetching VPN options:", error);
      return [];
    }
  };

  const handleAddBrands = async () => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      const vpn_config_brands = {
        vpn_enable: checkedbBrandsVpn,
      };
      const brandsUrl = Object.keys(selectedVpnUrls).map((brandId: any) => ({
        brand_id: Number(brandId), // Convert brandId to number
        vpn_url: selectedVpnUrls[brandId], // Get the VPN URL from the state
      }));
      const requestData = {
        operator_id: operatorIdP,
        brands_ids: checkedBrands,
        vpn_config_brands: vpn_config_brands,
        brand_block_arr: checkedBrandsBlock,
        vendor_ids: checkedProviders,
        brands_url: brandsUrl
      };
      const response = await addBrands(requestData);
      if (response && response.status === "success") {
        toast.success(
          response.message || "Brands Added to Operator successfully!",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          }
        );
        setanimationNavTab("4");
      } else {
        toast.error("Failed to add brands to operators.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add brands to operators.");
    }
  };

  const handleGamesList = async () => {
    try {
      setLoading5(true);
      const operatorIdP =
        tenentID !== undefined && tenentID !== null ? tenentID : operatorId;
      const vendorId = checkedProviders.join(",");
      const requestData = {
        provider_ids: vendorId,
        operator_id: operatorIdP,
        brands_ids: checkedBrands,
      };
      const response = await GameList(requestData);
      if (response?.status === "success") {
        const gameData: any[] = response.data.map((game: Game) => ({
          id: game.id,
          name: game.name,
          brand: game.games_brands,
          block_all_operators: game.block_all_operators,
        }));

        setGames(gameData || []);
        gameData.forEach((name) => {});
        const games = response.data;
        const filteredData = games.filter(
          (item: any) => item.block_all_operators === 1
        );
        const alreadysetGames = filteredData.map((item: any) => item.id);
        setCheckedGamesBlock(alreadysetGames);
      } else {
        const errorMessage = response?.message || "Failed to show Games.";
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      if (error === "Request failed with status code 401") {
        navigate("/login");
        toast.error("Token expired. Please login again.");
      } else {
      }
    } finally {
      setLoading5(false);
    }
  };

  const handleAddGames = async () => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }

      const requestData = {
        operator_id: operatorIdP,
        games_ids: checkedGames,
        games_block_arr: checkedbGamesBlock,
      };

      const response = await addGames(requestData);

      if (response && response.status === "success") {
        toast.success(
          response.message || "Games Added to Operator successfully!",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          }
        );
        setanimationNavTab("6");
      } else {
        toast.error(response.message || "Failed to add games to operators.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add games to operators.");
    }
  };

  const handleAddGGR = async () => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      const checkInputs = (inputType: string, errorMessage: string) => {
        const inputs = document.querySelectorAll<HTMLInputElement>(
          `input[type="number"][id^="${inputType}Input"]`
        );
        let isEmpty = false;

        inputs.forEach((input) => {
          if (input.value.trim() === "") {
            isEmpty = true;
          }
        });

        if (isEmpty) {
          toast.error(errorMessage);
        }

        return isEmpty;
      };

      if (
        checkInputs("ggr", "Please Insert GGR for all brands.") ||
        checkInputs("ngr", "Please Insert NGR for all brands.")
      ) {
        return;
      }

      const ggrBrandsData = brands.map((brand, index) => ({
        brand_id: brand.id,
        ggr_percent: ggrValues,
        ngr_percent: ngrValues,
      }));

      const percentages: Record<string, { ggr: string[]; ngr: string[] }> = {};

      ggrBrandsData.forEach((brand) => {
        Object.entries(brand.ggr_percent).forEach(([brandId, ggrPercent]) => {
          if (!percentages[brandId]) {
            percentages[brandId] = { ggr: [], ngr: [] };
          }
          percentages[brandId].ggr.push(ggrPercent);
        });

        Object.entries(brand.ngr_percent).forEach(([brandId, ngrPercent]) => {
          if (!percentages[brandId]) {
            percentages[brandId] = { ggr: [], ngr: [] };
          }
          percentages[brandId].ngr.push(ngrPercent);
        });
      });

      const finalBrands = Object.entries(percentages).map(
        ([brandId, { ggr, ngr }]) => ({
          brand_id: parseInt(brandId),
          ggr_percent: ggr[0],
          ngr_percent: ngr[0],
        })
      );

      const requestData = {
        operator_id: operatorIdP,
        ggr_brands: finalBrands,
      };

      const response = await addGgr(requestData);

      if (response && response.status === "success") {
        toast.success(response.message || "GGR Added  successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setanimationNavTab("5");
      } else {
        toast.error("Failed to add GGR .");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add GGR.");
    }
  };

  const toggleGGRandNGR = () => setModal(!modal);

  const fetchGGRandNgrHistory = async (brandId: any) => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      setLoading(true);
      const response = await GGRandNGRHistory(brandId, operatorIdP);
      setHistoryData(response.data);
      toggleGGRandNGR();
    } catch (error) {
      toast.error("Failed to fetch history data");
    } finally {
      setLoading(false);
      toggleGGRandNGR();
    }
  };

  const saveEdits = async (
    brandId: number,
    date: string,
    existingGgrPercent: string,
    existingNgrPercent: string
  ) => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      const formattedDate = `${date}-02`;
      const ggrPercent =
        editGgrValues[brandId] !== undefined
          ? editGgrValues[brandId]
          : existingGgrPercent;
      const ngrPercent =
        editNgrValues[brandId] !== undefined
          ? editNgrValues[brandId]
          : existingNgrPercent;
      const payload = {
        operator_id: operatorIdP,
        date: formattedDate,
        brand_id: brandId,
        ggr_percent: { [currentBrandId]: Number(ggrPercent) },
        ngr_percent: { [currentBrandId]: Number(ngrPercent) },
      };
      const response = await editGgrandNgr(payload);

      if (response.status === "success") {
        toast.success(response.message);
        fetchGGRandNgrHistory(brandId);
      } else {
        toast.error("Failed to update user providers:", response.message);
      }
      setEditingRow(null);
    } catch (error) {
      toast.error("Failed to update GGR and NGR data");
    }
  };

  const formatDate = (dateString: string, formatString: string): string => {
    const date = new Date(dateString);
    return format(date, formatString);
  };

  const saveData = async () => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      const requestData = {
        operator_id: operatorIdP,
        ip: ipValue,
      };

      const response = await IpWhitelist(requestData);

      if (response && response.status === "success") {
        setanimationNavTab("6");
        toast.success("WhiteList IP Added Successfully!");

        tog_varying1();
        iplist();
      } else {
        toast.error("Failed to add IP.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add IP.");
    }
  };

  const iplist = async () => {
    try {
      setLoading6(true);

      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      if (operatorIdP !== 0) {
        const ip = ipValue;
        const datas = await Iplist(operatorIdP, ip);
        const ipAddress = datas.data;
        const token = datas.system_access_token;

        setToken(token);
        setTokenExpDate(datas.token_expiry_date);
        setIpList(ipAddress);
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      if (error === "Request failed with status code 401") {
        navigate("/login");
        toast.error("Token expired. Please login again.");
      } else {
      }
    } finally {
      setLoading6(false);
    }
  };

  const creditlists = async () => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }

      const datas = await creditlist(operatorIdP);
      setCreditData(datas.data);
    } catch (error) {
      console.error("Error fetching data:", error);

      if (error === "Request failed with status code 401") {
        navigate("/login");
        toast.error("Token expired. Please login again.");
      }
    } finally {
      setLoading6(false);
    }
  };

  const handleSubmit = async () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      makeOwnerActive,
      kycVerified,
    } = formData;

    if (typeof id !== "undefined" && id > 0) {
      if (
        firstName.trim() === "" ||
        lastName.trim() === "" ||
        email.trim() === "" ||
        phoneNumber.length !== 10 ||
        password.trim() === "" ||
        !/^\S+@\S+\.\S+$/.test(email)
      ) {
        //  toast.warning('Please fill all fields correctly for adding a new admin!');
        //return;
      }
    }
    let operatorIdP: any;
    if (typeof tenentID !== "undefined" && tenentID !== null) {
      operatorIdP = tenentID;
    } else {
      operatorIdP = operatorId;
    }
    let idvar: any;

    if (isAddAdminUser === true) {
      setId(0);
      idvar = 0;
    } else {
      setId(idNew);
      idvar = idNew;
    }

    const requestBody = {
      id: idvar,
      first_name: firstName,
      last_name: lastName,
      email,
      kyc_regulated: kycVerified ? 1 : 0,
      phone_number: phoneNumber,
      password,

      operator_id: operatorIdP,

      operatorname: operatorName,
      owner_status: makeOwnerActive ? 1 : 0,
    };
    try {
      const bearerToken = getBearerToken();

      let response;
      response = await addOrUpdateAdmin(
        requestBody.operator_id,
        requestBody,
        bearerToken
      );

      if (response.success === true) {
        if (id) {
          toast.success(response.message || "Admin updated successfully!");
        } else {
          toast.success(response.message || "New Admin created successfully!");
        }

        setFormData({
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          password: "",
          makeOwnerActive: false,
          kycVerified: false,
        });
        setmodal_varying2(false);
      } else {
        if (response.data && response.data.email) {
          toast.error(response.data.email[0]); // Show "The email has already been taken."
        } else {
          toast.error(
            response.message || "Failed to submit data. Please try again later."
          );
        }
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
      console.error("API error:", error);
    }
  };

  const tenantamdinList = async () => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      if (operatorIdP > 0) {
        setTimeout(async () => {
          try {
            const response = await getListTenantAdmin(operatorIdP);
            setTenantAdminList(response.data.data);
          } catch (error: any) {
            console.error("Error fetching data:", error.message);
          }
        }, 1000);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
      if (error === "Request failed with status code 401") {
        navigate("/login");
        toast.error("Token expired. Please login again.");
      } else {
      }
    } finally {
      setLoading7(false);
    }
  };

  const advanceConfig = async () => {
    try {
      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }
      if (operatorIdP > 0) {
        setTimeout(async () => {
          try {
            const response = await getVendorsWithConfig(operatorIdP);
            // console.log(response);
            SetadvaConfigData(response);
          } catch (error: any) {
            console.error("Error fetching data:", error.message);
          }
        }, 1000);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
      if (error === "Request failed with status code 401") {
        navigate("/login");
        toast.error("Token expired. Please login again.");
      } else {
      }
    } finally {
      setLoading8(false);
    }
  };

  const openModal = (admin: TenantAdmin | null = null) => {
    if (admin) {
      setFormData({
        firstName: admin.first_name || "",
        lastName: admin.last_name || "",
        phoneNumber: admin.phone_number || "",
        email: admin.email || "",
        password: "",
        makeOwnerActive: false,
        kycVerified: admin.kyc_regulated === 1,
      });
      setId(admin.id);
      setIdNew(admin.id);
      setIsEditModal(true);
      setIsNewAdminClicked(false);
      setIsAddAdminUser(false);
    } else {
      if (!isNewAdminClicked) {
        setFormData({
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          password: "",
          makeOwnerActive: false,
          kycVerified: false,
        });
      }
      setIsEditModal(false);
      setIsNewAdminClicked(true);
      setIsAddAdminUser(true);
    }
    tog_varying2();
  };

  function tog_varying1() {
    setmodal_varying1(!modal_varying1);
  }
  const tog_viewconfig = async (vendor_id: any, operator_id: any) => {
    setmodal_viewconfig(!modal_viewconfig);
    //  let operatorIdP: any;
    // if (typeof tenentID !== 'undefined' && tenentID !== null) {
    //   operatorIdP = tenentID;
    // } else {
    //   operatorIdP = operatorId;setmodal_viewconfig
    // }

    const response = await getVendorsWithConfigSingle(vendor_id, operator_id);
    // console.log(response);
    SetsingleAdvaConfigData(response);
  };

  const tog_editconfig = async (vendor_id: any, operator_id: any) => {
    setmodal_editconfig(!modal_editconfig);
    setOpeIdEditConfig(operator_id);
    setVendorIdEditConfig(vendor_id);
    setEditSecretKeyValue("");
    setEditAPIKeyValue("");

    //  let operatorIdP: any;
    // if (typeof tenentID !== 'undefined' && tenentID !== null) {
    //   operatorIdP = tenentID;
    // } else {
    //   operatorIdP = operatorId;setmodal_viewconfig
    // }

    // const response = await getVendorsWithConfigSingle(vendor_id, operator_id);
    // console.log(response);
    // SetsingleAdvaConfigData(response);
  };

  const handleSaveEditConfig = async () => {
    // opeIdEditConfig;
    // vendorIdEditConfig;
    // editSecretKeyValue
    // editAPIKeyValue

    const response = await saveAdvanceConfig(
      opeIdEditConfig,
      vendorIdEditConfig,
      editAPIKeyValue,
      editSecretKeyValue
    );
    if (response.status === "success") {
      toast.success("Keys are added successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      setmodal_editconfig(false);
      advanceConfig();
    } else {
      toast.success("Please enter the keys", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const animationNavToggle = (tab: any) => {
    if (animationNavTab !== tab) {
      setanimationNavTab(tab);
    }
  };

  const handleDateChange = (selectedDates: any) => {
    const timezoneOffsetMinutes = selectedDates[0].getTimezoneOffset();
    const adjustedDate = new Date(
      selectedDates[0].getTime() - timezoneOffsetMinutes * 60 * 1000
    );
    const adj: any = adjustedDate.toISOString();
    setSelectedDates(adj);
  };

  function tog_varying2() {
    setmodal_varying2(!modal_varying2);
  }

  const generateTokenF = async () => {
    try {
      const expiry = selectedDates;

      let operatorIdP: any;
      if (typeof tenentID !== "undefined" && tenentID !== null) {
        operatorIdP = tenentID;
      } else {
        operatorIdP = operatorId;
      }

      const data = await generateToken(operatorIdP, expiry);

      if (data && data.status === "success") {
        setToken(data.token);
        setTokenExpDate(expiry);
        setModal_standard(false);

        toast.success(data.message || "Token generated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error(
          data.message || "Failed to generate token. Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      console.error("Error in generateTokenF:", error);

      toast.error("Failed to generate token. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleSingleSelectChange = async (
    selected: { label: string; value: string }[] | null
  ) => {
    try {
      setLoading3(true);
  
      if (!selected || selected.length === 0) {
        await handleBrandGamesList();
        return;
      }
  
      const gameProviderId = selected.map((item) => item.value);
      const response = await filteredBrands(gameProviderId);
  
      if (response && response.data && response.status === "success") {
        setBrands(response.data);
      } else {
        toast.error("Failed to show brands.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to show brands.");
    } finally {
      setLoading3(false);
    }
  };
  
  const handleMultiSelectChange = async (
    selected: { label: string; value: string } | null
  ) => {
    if (selected) {
      setSelectedCurrency(selected.value);
    } else {
      setSelectedCurrency(null);
    }
  };
  interface DatabaseOption {
    value: string;
    label: string;
  }

  // const databases: DatabaseOption[] = [
  //   { value: 'db1', label: 'Database 1' },
  //   { value: 'db2', label: 'Database 2' },
  // ];

  // const fetchDatabase = async () => {
  //   try {
  //     const response = await getDatabases();
  //     setDatabases(response);
  //   } catch (error) {
  //     toast.error("something went wrong")
  //   }
  // }

  // const handleDatabaseSelectChange = (selectedOption: DatabaseOption | null) => {
  //   const newSelectedDatabase = selectedOption ? selectedOption.value : '';
  //   setSelectedDatabase(newSelectedDatabase);
  //   console.log("Updated Selected Database:", newSelectedDatabase);
  // };
  const handleDatabaseSelectChange = (
    selectedOption: DatabaseOption | null
  ) => {
    if (selectedOption) {
      setSelectedDatabase(selectedOption.value);
      if (selectedOption.value === "create") {
        setModalOpen(true); // Open the modal when "Create a New Database" is selected
      }
    } else {
      setSelectedDatabase("");
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleCreate = async () => {
    alert("dbs");
    // Add your logic to create the database here
    try {
      const payload = {
        host: dbHost,
        user: dbUser,
        password: dbPassword,
        name: dbName,
        port: dbPort,
      };

      const result = await addDatabase(payload);
      // Optionally close the modal and reset state
      if (result.status === "success") {
        await fetchDatabase();
        toast.success(result.message || "Record Added Successfully!", {
          autoClose: 2000,
        });
        toggleModal(); // Assuming you have a function to close the modal
      }
      if (result.status === "error") {
        toast.error("Failed to connect to the provided database.");
      }

      // Reset fields if needed
      setDbHost("");
      setDbUser("");
      setDbPassword("");
      setDbName("");
      setDbPort("");
    } catch (error) {
      console.error("Failed to create database:", error);
    }
    toggleModal();
  };

  brands
    .filter((brand) =>
      brand.brand_name.toLowerCase().includes(searchBrandInput.toLowerCase())
    )
    .map((brand, index) => <div key={index}>{brand.brand_name}</div>);

  const filteredProviders = provider.filter((provider) =>
    checkedProviders.includes(provider.id)
  );

  const brandsOptions = filteredProviders.map((provider) => ({
    label: provider.vendor_name,
    value: provider.id,
  }));
  const selected_provider_ids = filteredProviders.map(
    (provider) => provider.id
  );

  let defaultOpt: any = {
    label: "SELECT ALL",
    value: "" + selected_provider_ids,
  };
  brandsOptions.unshift(defaultOpt);

  const findBrandById = (brandId: number) => {
    return brands.find((brand) => brand.id === brandId);
  };

  const roles = [{ label: "Admin", value: "admin" }];

  if (tenentID) {
    document.title = t("Edit Operator");
  } else {
    document.title = t("Add Operator");
  }

  const tabs = [
    {
      permission: "operator_tenants.assign_provider",
      label: t("Manage Providers"),
      onClick: () => animationNavToggle("2"),
    },
    {
      permission: "operator_tenants.manage_brand",
      label: t("Manage Brands"),
      onClick: () => {
        animationNavToggle("3");
        handleBrandGamesList();
      },
    },
    {
      permission: "operator_tenants.manage_ggr",
      label: t("Manage GGR"),
      onClick: () => {
        animationNavToggle("4");
        handleBrandGamesList();
      },
    },
    {
      permission: "operator_tenants.manage_games",
      label: t("Manage Games"),
      onClick: () => {
        animationNavToggle("5");
        handleBrandGamesList();
        handleGamesList();
      },
    },
    {
      permission: "operator_tenants.token_expiry",
      label: t("Expiry of Token"),
      onClick: () => {
        animationNavToggle("6");
        iplist();
      },
    },
    {
      permission: "operator_tenants.view_tenant_admin",
      label: t("Tenant Admin"),
      onClick: () => {
        animationNavToggle("7");
        tenantamdinList();
      },
    },
    {
      permission: "operator_tenants.advanceConfig",
      label: t("Advance Config"),
      onClick: () => {
        animationNavToggle("8");
        advanceConfig();
      },
    },
  ];

  const breadcrumbTitle = tenentID ? t("Edit Operator") : t("Add Operator");

  return (
    <React.Fragment>
      <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={operatorName} pageTitle={t(breadcrumbTitle)} />
            <div>
              <Row className="align-items-center">
                <Col lg={12}>
                  <div className="d-flex justify-content-end align-item-center">
                    <Link to="/Operator-tenant">
                      <button className="btn btn-primary">
                        {" "}
                        <i className=" ri-arrow-left-line"></i>
                        {t("Back")}
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="d-flex align-items-end justify-content-center mt-3">
              <Col xs={12} md={12} lg={12} xxl={12}>
                <Card md={12} lg={12} xxl={8}>
                  <CardBody>
                    <Nav
                      md={12}
                      lg={12}
                      pills
                      className="nav nav-pills animation-nav nav-justified gap-2 mb-3 text-body"
                    >
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                          className={classnames({
                            active: animationNavTab === "1",
                          })}
                          onClick={() => {
                            animationNavToggle("1");
                          }}
                        >
                          {t("Details")}
                        </NavLink>
                      </NavItem>
                      {tabs.map(
                        (tab, index) =>
                          PermissionHelper.PermissionChecker(
                            tab.permission
                          ) && (
                            <NavItem key={index}>
                              <NavLink
                                style={{
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                }}
                                className={classnames({
                                  active:
                                    animationNavTab === (index + 2).toString(),
                                })}
                                onClick={tab.onClick}
                              >
                                {tab.label}
                              </NavLink>
                            </NavItem>
                          )
                      )}
                    </Nav>

                    <TabContent activeTab={animationNavTab}>
                      <TabPane tabId="1" id="animation-home">
                        {loading1 ? (
                          <div>
                            <Row className="mb-3">
                              <Col lg={2}>
                                <Skeleton height={30} width={150} />
                              </Col>
                              <Col lg={4}>
                                <Skeleton height={30} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={2}>
                                <Skeleton height={30} width={150} />
                              </Col>
                              <Col lg={4}>
                                <Skeleton height={30} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={2}>
                                <Skeleton height={30} width={150} />
                              </Col>
                              <Col lg={4} md={4} xs={9}>
                                <Skeleton height={30} />
                              </Col>
                              <Col lg={3} md={2} xs={3}>
                                <Skeleton height={30} width={500} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={2}>
                                <Skeleton height={30} width={150} />
                              </Col>
                              <Col lg={4} md={4} xs={9}>
                                <Skeleton height={30} />
                              </Col>
                            </Row>
                            <div className="text-end">
                              <Skeleton height={40} width={100} />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <Card md={12} lg={12} xxl={8} className="mb-3">
                              <CardBody>
                                <Row className="mb-3">
                                  <Col lg={2}>
                                    <Label
                                      htmlFor="nameInput"
                                      className="form-label mt-2"
                                    >
                                      {t(" Operator Name")}
                                    </Label>
                                  </Col>
                                  <Col lg={4}>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="nameInput"
                                      value={operatorName}
                                      onChange={(e) =>
                                        setOperatorName(e.target.value)
                                      }
                                      placeholder={t("Operator Name")}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col lg={2}>
                                    <Label
                                      htmlFor="nameInput"
                                      className="form-label mt-2"
                                    >
                                      {t("Operator Domain")}
                                    </Label>
                                  </Col>
                                  <Col lg={4}>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="domainInput"
                                      onChange={(e) =>
                                        setOperatorDomain(e.target.value)
                                      }
                                      value={operatorDomain}
                                      placeholder={t("Operator Domain")}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col lg={2}>
                                    <Label
                                      htmlFor="nameInput"
                                      className="form-label mt-2"
                                    >
                                      {t("CallBack URL")}
                                    </Label>
                                  </Col>
                                  <Col lg={4}>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="CallbackInput"
                                      onChange={(e) =>
                                        setCallbackUrl(e.target.value)
                                      }
                                      value={callbackUrl}
                                      placeholder={t("CallBack URL")}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col lg={2}>
                                    <Label
                                      htmlFor="nameInput"
                                      className="form-label mt-2"
                                    >
                                      {t("Select Bet Limit for Each user")}
                                    </Label>
                                  </Col>
                                  <Col lg={4}>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="creditInput"
                                      onChange={(e) =>
                                        setBetLimit(e.target.value)
                                      }
                                      value={betLimit}
                                      placeholder={t("Betting Limit")}
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <Label
                                      htmlFor="nameInput"
                                      className="form-label mt-2"
                                    >
                                      <strong>
                                        € &nbsp;&nbsp; &nbsp;
                                        {t(
                                          "In case you dont want the player to bet on front end, please make this value as"
                                        )}{" "}
                                        <span style={{ color: "red" }}>0</span>{" "}
                                        €
                                      </strong>
                                    </Label>
                                  </Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col lg={2}>
                                    <Label
                                      htmlFor="nameInput"
                                      className="form-label mt-2"
                                    >
                                      {t("Select Currency of Operator")}
                                    </Label>
                                  </Col>
                                  <Col lg={4}>
                                    <Select
                                      value={currencies.find(
                                        (currency) =>
                                          currency.value === selectedCurrency
                                      )}
                                      onChange={handleMultiSelectChange}
                                      options={currencies}
                                      placeholder={
                                        "Select from list of currencies"
                                      }
                                      isSearchable={true}
                                      isDisabled={!!tenentID}
                                    />
                                    {tenentID && (
                                      <small className="text-muted">
                                        {t("Currency edit is not Applicable.")}
                                      </small>
                                    )}
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col lg={2}>
                                    <Label
                                      htmlFor="dedicatedDbCheckbox"
                                      className="form-label mt-2"
                                    >
                                      {t("Use Dedicated Database")}
                                    </Label>
                                  </Col>
                                  <Col
                                    lg={4}
                                    className="d-flex align-items-center"
                                  >
                                    <Input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="dedicatedDbCheckbox"
                                      checked={isDedicatedDb}
                                      onChange={(e) =>
                                        setIsDedicatedDb(e.target.checked)
                                      }
                                    />
                                  </Col>
                                </Row>

                                {/* Conditionally render dropdown and input fields based on isDedicatedDb state */}
                                {isDedicatedDb && (
                                  <>
                                    <Row className="mb-3">
                                      <Col lg={2}>
                                        <Label
                                          htmlFor="databaseSelect"
                                          className="form-label mt-1"
                                        >
                                          {/* {t('Select or Create Database')} */}
                                        </Label>
                                      </Col>
                                      <Col lg={4}>
                                        <Select
                                          id="databaseSelect"
                                          value={
                                            newDbs.find(
                                              (db) =>
                                                db.value === selectedDatabase
                                            ) || null
                                          }
                                          onChange={handleDatabaseSelectChange}
                                          options={[
                                            {
                                              value: "create",
                                              label: t("Create a New Database"),
                                            },
                                            ...newDbs,
                                          ]}
                                          placeholder={t(
                                            "Select from available databases"
                                          )}
                                          isSearchable={true}
                                          isClearable={true}
                                        />
                                      </Col>
                                    </Row>

                                    {/* Add the modal component here */}
                                    <CreateDatabaseModal
                                      isOpen={modalOpen}
                                      toggle={toggleModal}
                                      dbHost={dbHost}
                                      setDbHost={setDbHost}
                                      dbUser={dbUser}
                                      setDbUser={setDbUser}
                                      dbPassword={dbPassword}
                                      setDbPassword={setDbPassword}
                                      dbName={dbName}
                                      setDbName={setDbName}
                                      dbPort={dbPort}
                                      setDbPort={setDbPort}
                                      handleCreate={handleCreate}
                                    />
                                  </>
                                )}

                                {/* <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleAddOperator}
                                  >
                                    {t('Save Operator')}
                                  </button>
                                </div> */}

                                <div className="text-end">
                                  {tenentID ? (
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        handleAddOperator();
                                      }}
                                    >
                                      {t("Save Operator")}
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        handleAddOperator();
                                      }}
                                    >
                                      {t("Save Operator")}
                                    </button>
                                  )}
                                </div>
                              </CardBody>
                            </Card>
                            <Card
                              md={12}
                              lg={12}
                              xxl={8}
                              style={{ backgroundColor: "#f3f3f9" }}
                              className="mb-3"
                            >
                              <CardBody>
                                <Row className="mb-3">
                                  <Col lg={2}>
                                    <Label
                                      htmlFor="nameInput"
                                      className="form-label mt-2"
                                    >
                                      {t("Set the Credit Limit Here")}
                                    </Label>
                                  </Col>
                                  <Col lg={3}>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="creditInput"
                                      onChange={(e) =>
                                        setCredit(e.target.value)
                                      }
                                      value={credit}
                                      placeholder={t("Credit")}
                                    />
                                  </Col>
                                  <Col lg={3}>
                                    <Label
                                      htmlFor="nameInput"
                                      className="form-label mt-2"
                                    >
                                      <strong>€</strong>
                                    </Label>
                                  </Col>
                                  <Col lg={2}>
                                    <Label
                                      htmlFor="nameInput"
                                      className="form-label mt-2"
                                    >
                                      {t("Remaining Credit")} :{" "}
                                      {remainingCredit} <strong>€ </strong>
                                    </Label>
                                  </Col>
                                </Row>
                                <div className="text-end ">
                                  <button
                                    className="btn btn-success"
                                    disabled={isAddCreditButtonDisabled} 
                                    onClick={() => {
                                      handleAddCredits();
                                    }}
                                  >
                                    {t("Add credit")}
                                  </button>
                                  <span style={{ marginRight: "10px" }}></span>
                                  <button
                                    className="btn btn-danger"
                                    disabled={isRemoveCreditButtonDisabled}
                                    onClick={() => {
                                      handleRemoveCredits();
                                    }}
                                  >
                                    {t("Remove credit")}
                                  </button>
                                </div>
                                <div className="table-responsive mt-5">
                                  <Table className="table-striped table-nowrap align-middle mb-0">
                                    <thead>
                                      <tr className="text-center">
                                        <th scope="col">{t("Date")}</th>
                                        <th scope="col">
                                          {t("Amount of Credit / Debit")} (€)
                                        </th>
                                        <th scope="col">
                                          {t("Previous Balance")} (€)
                                        </th>
                                        <th scope="col">
                                          {t("New Balance")} (€)
                                        </th>
                                        <th scope="col">Ip</th>
                                        <th scope="col">{t("Given By")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading1 ? (
                                        Array.from({
                                          length: creditData.length || 3,
                                        }).map((_, index) => (
                                          <tr
                                            key={index}
                                            className="text-center"
                                          >
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                          </tr>
                                        ))
                                      ) : creditData &&
                                        creditData.length > 0 ? (
                                        creditData.map((Credit: any) => (
                                          <tr
                                            className="text-center"
                                            key={Credit.id}
                                          >
                                            <td>
                                              {new Date(
                                                Credit.created_at
                                              ).toLocaleDateString("en-US", {
                                                weekday: "long",
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                              })}
                                            </td>
                                            <td>{Credit.amount}</td>
                                            <td>{Credit.previous_balance}</td>
                                            <td>{Credit.total_balance}</td>
                                            <td>{Credit.ip_address}</td>
                                            <td>{Credit.given_by_username}</td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr></tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        )}
                      </TabPane>

                      <TabPane tabId="2" id="animation-profile">
                        <Row>
                          <Col lg={12}>
                            {loading2 || loadingCheckboxes ? (
                              <Skeleton height={80} />
                            ) : (
                              <Alert
                                color="primary"
                                className="d-flex flex-column"
                              >
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                  <strong className="mb-0 mr-2">
                                    {t("Operator Name")}{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;{" "}
                                  </strong>
                                  <strong className="mb-0">
                                    {operatorName}
                                  </strong>
                                </div>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                  <strong className="mb-0 mr-2">
                                    {t("Operator Domain")}&nbsp;: &nbsp;&nbsp;{" "}
                                  </strong>
                                  <strong className="mb-0">
                                    {operatorDomain}
                                  </strong>
                                </div>
                              </Alert>
                            )}
                          </Col>
                        </Row>

                        <Row className="align-items-center border-dark mt-5">
                          <Col
                            xs={12}
                            sm={4}
                            lg={4}
                            md={4}
                            className="align-items-end"
                          >
                            <strong>{t("Add Game Provider")}</strong>
                          </Col>
                          <div>
                            <Table>
                              <thead>
                                <tr className="text-center">
                                  <th>{t("Game Provider")}</th>
                                  <th>{t("Enable VPN")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loading2 || loadingCheckboxes ? (
                                  Array.from({
                                    length: provider.length || 5,
                                  }).map((_, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                    </tr>
                                  ))
                                ) : provider && provider.length > 0 ? (
                                  provider.map((provider, index) => (
                                    <tr className="hover-effect" key={index}>
                                      <td>
                                        <div
                                          className={`text-center form-check form-check-${
                                            colors[index % colors.length]
                                          }`}
                                          style={{
                                            display: "flex",
                                            justifyContent: "right",
                                          }}
                                        >
                                          <div className="manage-input-main">
                                            <div className="manage-input">
                                              <input
                                                value={provider.id}
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`formCheck${index}`}
                                                onChange={(e) =>
                                                  handleCheckboxChange(
                                                    e,
                                                    provider.id
                                                  )
                                                }
                                                checked={checkedProviders.includes(
                                                  provider.id
                                                )}
                                              />
                                              &nbsp;
                                              <label
                                                className="form-check-label"
                                                htmlFor={`formCheck${index}`}
                                              >
                                                {provider.vendor_name}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className={`text-center form-check form-check-${
                                            colors[index % colors.length]
                                          }`}
                                        >
                                          <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`vpnCheckboxProvider${index}`}
                                            onChange={() =>
                                              handleCheckboxChangeVPN(index)
                                            }
                                            checked={
                                              checkboxValues[index] === 1
                                            }
                                          />
                                          &nbsp;&nbsp;
                                          <Label
                                            className="form-check-label"
                                            htmlFor={`vpnCheckboxProvider${index}`}
                                          >
                                            Enable VPN
                                          </Label>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td>{t("No Game Providers Available")}</td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Row>

                        <div className="text-end mt-5">
                          {tenentID ? (
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                handleAddProviderToOpr();
                                handleBrandGamesList();
                              }}
                            >
                              {t("Save")}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                handleAddProviderToOpr();
                                handleBrandGamesList();
                              }}
                            >
                              {t("Save")}
                            </button>
                          )}
                        </div>
                      </TabPane>

                      <TabPane tabId="3" id="animation-games">
                        <Row>
                          <Col lg={12}>
                            {loading3 ? (
                              <Skeleton height={80} />
                            ) : (
                              <Alert
                                color="primary"
                                className="d-flex flex-column"
                              >
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                  <strong className="mb-0 mr-2">
                                    {t("Operator Name")}{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;{" "}
                                  </strong>
                                  <strong className="mb-0">
                                    {operatorName}
                                  </strong>
                                </div>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                  <strong className="mb-0 mr-2">
                                    {t("Operator Domain")}&nbsp;: &nbsp;&nbsp;{" "}
                                  </strong>
                                  <strong className="mb-0">
                                    {operatorDomain}
                                  </strong>
                                </div>
                              </Alert>
                            )}
                          </Col>
                        </Row>

                        <Row className="align-items-center mt-3">
                          <Col
                            xs={12}
                            sm={4}
                            lg={4}
                            md={4}
                            className=" align-items-end"
                          >
                            <strong>{t("Selected Game Provider")}</strong>
                          </Col>
                          <Col
                            xs={12}
                            sm={4}
                            lg={3}
                            md={4}
                            className="align-items-end mt-3 mb-3 mt-xl-0 mb-xl-0"
                          >
                            <Select
                              onChange={handleSingleSelectChange}
                              options={brandsOptions}
                              placeholder={t("Select Provider")}
                              isSearchable={true}
                              isMulti={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            sm={4}
                            lg={3}
                            md={4}
                            className="align-items-end mt-3 mb-3 mt-xl-0 mb-xl-0"
                          >
                            <Input
                              type="text"
                              value={searchBrandInput}
                              onChange={(e) =>
                                setSearchBrandInput(e.target.value)
                              }
                              placeholder={t("Search Brand")}
                            />
                          </Col>
                        </Row>
                        <Row className="align-items-center border-dark mt-3">
                          <Col
                            xs={12}
                            sm={4}
                            lg={4}
                            md={4}
                            className="align-items-end"
                          >
                            <strong>{t("Select a Brand")} </strong>
                          </Col>
                          <div className="table-responsive">
                            <Table>
                              <thead>
                                <tr className="text-center">
                                  <th>{t("Brands")}</th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    {t("Enable VPN")}
                                  </th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    {t("Block for All Operators")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="text2">
                                    <div className="manage-input-main" style={{ display: 'flex',  width: '100%' }}>                
                                                      <Input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="selectAllGames"
                                          value={checkedAllBrands}
                                          onChange={handleSelectAllBrands}
                                        />
                                        &nbsp; &nbsp;
                                        <Label
                                          className="form-check-label"
                                          htmlFor="selectAllGames"
                                          style={{ whiteSpace: 'nowrap', flexShrink: 0 }} // Prevent shrinking
                                        >
                                          <h5>
                                            <strong
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {t("SELECT ALL BRANDS")}
                                            </strong>
                                          </h5>
                                        </Label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {loading3 ? (
                                  Array.from({
                                    length: brands.length || 5,
                                  }).map((_, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                    </tr>
                                  ))
                                ) : brands && brands.length > 0 ? (
                                  brands
                                    .filter((brand) =>
                                      brand.brand_name
                                        .toLowerCase()
                                        .includes(
                                          searchBrandInput.toLowerCase()
                                        )
                                    )
                                    .map((brand, index) => (
                                      <tr className="hover-effect " key={index}>
                                        <td>
                                          <div
                                            className={`text-center custom-center form-check form-check-${
                                              colors[index % colors.length]
                                            }`}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              
                                            }}
                                          >
                                            <div className="manage-input-main">
                                              <div className="manage-input">
                                                <input
                                                  value={brand.id}
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id={`brandCheck${index}`}
                                                  onChange={(e) =>
                                                    handleCheckboxChangeBrands(
                                                      e,
                                                      brand.id
                                                    )
                                                  }
                                                  checked={checkedBrands.includes(
                                                    brand.id
                                                  )}
                                                />{" "}
                                                &nbsp;
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`brandCheck${index}`}
                                                >
                                                  {brand.brand_name}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center', 
                                              width: '100%', 
                                            }}
                                            className={`text-center form-check form-check-${
                                              colors[index % colors.length]
                                            }`}
                                          >
                                            <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`vpnCheckbox${index}`}
                                              onChange={(e) =>
                                                handleVpnCheckboxBrands(
                                                  e,
                                                  brand.id
                                                )
                                              }
                                              checked={checkedbBrandsVpn.includes(
                                                brand.id
                                              )}
                                            />
                                            &nbsp;&nbsp;
                                            <Label
                                              className="form-check-label"
                                              htmlFor={`vpnCheckbox${index}`}
                                            >
                                              Enable VPN
                                            </Label>
                                            {checkedbBrandsVpn?.includes(brand.id) && (
                                               <div className="ms-3" style={{ marginLeft: 'auto', minWidth: '200px' }}>   
                                                                                          <Select
                                                  options={vpnDropdowns.map((url: string) => ({
                                                    value: url,
                                                    label: url,
                                                  }))}
                                                  onChange={(selectedOption: any) => handleVpnChange(brand.id, selectedOption)}
                                                  value={
                                                    selectedVpnUrls[brand.id]
                                                      ? { value: selectedVpnUrls[brand.id], label: selectedVpnUrls[brand.id] }
                                                      : null // Make sure this points to the selected value
                                                  }
                                                  placeholder="Select VPN Option"
                                                  className="vpn-dropdown"
                                                />
                                              </div>
                                            )}


                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            className={`text-center form-check form-check-${
                                              colors[index % colors.length]
                                            }`}
                                          >
                                            <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`gameCheckBlock${index}`}
                                              value={checkedBrandsBlock.join(
                                                ","
                                              )}
                                              onChange={(e) =>
                                                handleCheckboxBlockBrands(
                                                  e,
                                                  brand.id
                                                )
                                              }
                                              checked={checkedBrandsBlock.includes(
                                                brand.id
                                              )}
                                            />
                                            &nbsp;&nbsp;
                                            <Label
                                              className="form-check-label"
                                              htmlFor={`gameCheckBlock${index}`}
                                            >
                                              Block for All Operators
                                            </Label>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                ) : (
                                  <tr>
                                    <td colSpan={3} className="text-center">
                                      <strong>
                                        {t("No Brands Available")}
                                      </strong>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Row>

                        <div className="text-end mt-5">
                          {tenentID ? (
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                handleAddBrands();
                                handleGamesList();
                              }}
                            >
                              {t("Save")}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                handleAddBrands();
                                handleGamesList();
                              }}
                            >
                              {t("Save")}
                            </button>
                          )}
                        </div>
                      </TabPane>

                      <TabPane tabId="4" id="animation-games">
                        <Row>
                          <Col lg={12}>
                            <Alert
                              color="primary"
                              className="d-flex flex-column"
                            >
                              <div className="d-flex justify-content-start align-items-center mb-2">
                                <strong className="mb-0 mr-2">
                                  {t("Operator Name")} &nbsp;&nbsp;&nbsp;&nbsp;
                                  : &nbsp;{" "}
                                </strong>
                                <strong className="mb-0">{operatorName}</strong>
                              </div>
                              <div className="d-flex justify-content-start align-items-center mt-2">
                                <strong className="mb-0 mr-2">
                                  {t("Operator Domain")} &nbsp;: &nbsp;&nbsp;{" "}
                                </strong>
                                <strong className="mb-0">
                                  {operatorDomain}
                                </strong>
                              </div>
                            </Alert>
                          </Col>
                        </Row>

                        <div>
                          {checkedBrands.map((brandId, index) => {
                            const brand = findBrandById(brandId);

                            if (!brand) {
                              return null;
                            }

                            return (
                              <Row key={brand.id} className="mb-3">
                                <Col lg={2}>
                                  <Label
                                    htmlFor={`ggrInput${brand.id}`}
                                    className="form-label mt-2"
                                  >
                                    {t("Select")} GGR {t("for")}{" "}
                                    {brand.brand_name}
                                  </Label>
                                </Col>
                                <Col lg={2} md={4} xs={9}>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    value={ggrValues[brand.id]}
                                    id={`ggrInput${brand.id}`}
                                    onChange={(e) => {
                                      const value: any = Number(e.target.value);
                                      if (value <= 100) {
                                        handleGgrChange(brand.id, value);
                                      } else {
                                        toast.error(
                                          "GGR cannot be more than 100"
                                        );
                                      }
                                    }}
                                    placeholder="GGR"
                                  />
                                </Col>
                                <Col lg={1} md={2} xs={3}>
                                  <Label
                                    htmlFor={`ggrInput${brand.id}`}
                                    className="form-label mt-2"
                                  >
                                    <strong>% </strong>
                                  </Label>
                                </Col>
                                <Col lg={2} md={1} xs={2}>
                                  <Button
                                    color="link"
                                    onClick={() => {
                                      setCurrentBrandId(brand.id);
                                      fetchGGRandNgrHistory(brand.id);
                                    }}
                                  >
                                    <FeatherIcon icon="eye" />
                                  </Button>
                                </Col>
                                <Col lg={2}>
                                  <Label
                                    htmlFor={`ngrInput${brand.id}`}
                                    className="form-label mt-2"
                                  >
                                    {t("Select")} NGR {t("for")}{" "}
                                    {brand.brand_name}
                                  </Label>
                                </Col>
                                <Col lg={2} md={4} xs={9}>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    value={ngrValues[brand.id]}
                                    id={`ngrInput${brand.id}`}
                                    onChange={(e) => {
                                      const value: any = Number(e.target.value);
                                      if (value <= 100) {
                                        handleNgrChange(brand.id, value);
                                      } else {
                                        toast.error(
                                          "NGR cannot be more than 100"
                                        );
                                      }
                                    }}
                                    placeholder="NGR"
                                  />
                                </Col>
                                <Col lg={1} md={2} xs={3}>
                                  <Label
                                    htmlFor={`ngrInput${brand.id}`}
                                    className="form-label mt-2"
                                  >
                                    <strong>% </strong>
                                  </Label>
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                        <div className="text-end">
                          {tenentID ? (
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={() => {
                                handleAddGGR();
                                handleGamesList();
                              }}
                            >
                              {t("Save")}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={() => {
                                handleAddGGR();
                                handleGamesList();
                              }}
                            >
                              {t("Save")}
                            </button>
                          )}
                        </div>

                        <Modal
                          isOpen={modal}
                          toggle={toggleGGRandNGR}
                          size="lg"
                        >
                          <ModalHeader toggle={toggleGGRandNGR}>
                            Brands History
                          </ModalHeader>
                          <ModalBody>
                            {loading ? (
                              <p>Loading...</p>
                            ) : (
                              <div>
                                <h5>
                                  GGR & NGR History For{" "}
                                  {
                                    findBrandById(historyData[0]?.brand_id)
                                      ?.brand_name
                                  }
                                </h5>
                                {historyData && historyData.length ? (
                                  <div className="table-responsive">
                                    <Table>
                                      <thead>
                                        <tr className="text-center">
                                          <th>Month/Year</th>
                                          <th>GGR %</th>
                                          <th>NGR %</th>
                                          <th>Updated At</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {historyData.map((record) => (
                                          <tr
                                            key={record.id}
                                            className="text-center"
                                          >
                                            <td className="col-2">
                                              {formatDate(
                                                record.month_year,
                                                "MMMM yyyy"
                                              )}
                                            </td>
                                            <td className="col-2">
                                              {editingRow === record.id ? (
                                                <Input
                                                  type="number"
                                                  className="form-control"
                                                  value={
                                                    editGgrValues[
                                                      currentBrandId
                                                    ] !== undefined
                                                      ? editGgrValues[
                                                          currentBrandId
                                                        ]
                                                      : record.ggr_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleEditGgrChange(
                                                      currentBrandId,
                                                      Number(e.target.value)
                                                    )
                                                  }
                                                />
                                              ) : (
                                                `${record.ggr_percent} %`
                                              )}
                                            </td>
                                            <td className="col-2">
                                              {editingRow === record.id ? (
                                                <Input
                                                  type="number"
                                                  className="form-control"
                                                  value={
                                                    editNgrValues[
                                                      currentBrandId
                                                    ] !== undefined
                                                      ? editNgrValues[
                                                          currentBrandId
                                                        ]
                                                      : record.ngr_percent
                                                  }
                                                  onChange={(e) =>
                                                    handleEditNgrChange(
                                                      currentBrandId,
                                                      Number(e.target.value)
                                                    )
                                                  }
                                                />
                                              ) : (
                                                `${record.ngr_percent} %`
                                              )}
                                            </td>
                                            <td className="col-3">
                                              {formatDate(
                                                record.updated_at,
                                                "MMMM dd, yyyy HH:mm"
                                              )}
                                            </td>
                                            <td className="col">
                                              {editingRow === record.id ? (
                                                <Button
                                                  color="primary"
                                                  onClick={() =>
                                                    saveEdits(
                                                      currentBrandId,
                                                      record.month_year,
                                                      record.ggr_percent,
                                                      record.ngr_percent
                                                    )
                                                  }
                                                >
                                                  Save
                                                </Button>
                                              ) : (
                                                <Button
                                                  color="link"
                                                  onClick={() =>
                                                    setEditingRow(record.id)
                                                  }
                                                >
                                                  <FeatherIcon icon="edit-2" />
                                                </Button>
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </div>
                                ) : (
                                  <p>No data available</p>
                                )}
                              </div>
                            )}
                          </ModalBody>
                        </Modal>
                      </TabPane>

                      <TabPane tabId="5" id="animation-games">
                        <Row>
                          <Col lg={12}>
                            {loading5 ? (
                              <Skeleton height={80} />
                            ) : (
                              <Alert
                                color="primary"
                                className="d-flex flex-column"
                              >
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                  <strong className="mb-0 mr-2">
                                    {t("Operator Name")}{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;{" "}
                                  </strong>
                                  <strong className="mb-0">
                                    {operatorName}
                                  </strong>
                                </div>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                  <strong className="mb-0 mr-2">
                                    {t("Operator Domain")} &nbsp;: &nbsp;&nbsp;{" "}
                                  </strong>
                                  <strong className="mb-0">
                                    {operatorDomain}
                                  </strong>
                                </div>
                              </Alert>
                            )}
                          </Col>
                        </Row>

                        <Row className="align-items-center border-dark mt-3">
                          <div className="table-responsive">
                            <Table>
                              <thead>
                                <tr className="text-center">
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    {t("Select the Games you Want to show")}
                                  </th>
                                  <th>{t("Brands")}</th>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    {t("Block for All Operators")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="text2 ">
                                      <div className=" manage-input-main ">
                                        <Input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="selectAllGames"
                                          value={checkedAllGames}
                                          onChange={handleSelectAllGames}
                                        />
                                        &nbsp; &nbsp;
                                        <Label
                                          className="form-check-label"
                                          htmlFor="selectAllGames"
                                        >
                                          <h5>
                                            <strong
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {t("SELECT ALL GAMES")}
                                            </strong>
                                          </h5>
                                        </Label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                {loading5 ? (
                                  Array.from({ length: games.length || 5 }).map(
                                    (_, index) => (
                                      <tr key={index}>
                                        <td>
                                          <Skeleton height={30} />
                                        </td>
                                        <td>
                                          <Skeleton height={30} />
                                        </td>
                                        <td>
                                          <Skeleton height={30} />
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : games && games.length > 0 ? (
                                  games.map((game, index) => (
                                    <tr className="hover-effect" key={index}>
                                      <td>
                                        <div
                                          className={`text2 form-check form-check-${
                                            colors[index % colors.length]
                                          }`}
                                        >
                                          <div className="manage-input-main">
                                            <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`gameCheck${index}`}
                                              value={checkedGames}
                                              onChange={(e) =>
                                                handleGamesCheckbox(e, game.id)
                                              }
                                              checked={checkedGames.includes(
                                                game.id
                                              )}
                                            />
                                            &nbsp;&nbsp;
                                            <Label
                                              className="form-check-label"
                                              htmlFor={`gameCheck${index}`}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <span>{game.name}</span>
                                            </Label>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className={`text-center form-check form-check-${
                                            colors[index % colors.length]
                                          }`}
                                        >
                                          <Label>
                                            <span>{game.brand}</span>
                                          </Label>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className={`text-center form-check form-check-${
                                            colors[index % colors.length]
                                          }`}
                                        >
                                          <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`gameCheckBlock${index}`}
                                            value={checkedbGamesBlock.join(",")}
                                            onChange={(e) =>
                                              handleCheckboxBlockGame(
                                                e,
                                                game.id
                                              )
                                            }
                                            checked={checkedbGamesBlock.includes(
                                              game.id
                                            )}
                                          />
                                          &nbsp;&nbsp;
                                          <Label
                                            className="form-check-label"
                                            htmlFor={`gameCheckBlock${index}`}
                                          >
                                            Block for All Operators
                                          </Label>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={3} className="text-center">
                                      <strong>{t("No Games Available")}</strong>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Row>

                        <div className="text-end mt-5">
                          {tenentID ? (
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                handleAddGames();
                                iplist();
                              }}
                            >
                              {t("Save")}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                handleAddGames();
                                iplist();
                              }}
                            >
                              {t("Save")}
                            </button>
                          )}
                        </div>
                      </TabPane>

                      <TabPane tabId="6" id="animation-messages">
                        <Row>
                          <Col lg={12}>
                            {loading6 ? (
                              <Skeleton height={80} />
                            ) : (
                              <Alert
                                color="primary"
                                className="d-flex flex-column"
                              >
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                  <strong className="mb-0 mr-2">
                                    {t("Operator Name")}{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;{" "}
                                  </strong>
                                  <strong className="mb-0">
                                    {operatorName}
                                  </strong>
                                </div>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                  <strong className="mb-0 mr-2">
                                    {t("Operator Domain")} &nbsp;: &nbsp;&nbsp;{" "}
                                  </strong>
                                  <strong className="mb-0">
                                    {operatorDomain}
                                  </strong>
                                </div>
                              </Alert>
                            )}
                          </Col>
                        </Row>

                        <strong>{t("Current Token Being Used")}</strong>
                        <div className="d-flex justify-content-end align-items-center">
                          {loading6 ? (
                            <Skeleton height={30} width={150} />
                          ) : (
                            <Button
                              color="primary"
                              onClick={() => tog_varying1()}
                            >
                              {t("Add IP to Whitelist")}
                            </Button>
                          )}
                        </div>
                        <Modal
                          isOpen={modal_varying1}
                          toggle={() => {
                            tog_varying1();
                          }}
                          id="exampleModal"
                          modalClassName="flip"
                          centered
                        >
                          <ModalHeader>
                            {t("Add IP to Whitelist in area below")}
                            <Button
                              type="button"
                              className="btn-close"
                              onClick={() => {
                                setmodal_varying1(false);
                              }}
                              aria-label="Close"
                            ></Button>
                          </ModalHeader>
                          <ModalBody>
                            <form>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  className="form-control text-black"
                                  id="ip"
                                  value={ipValue}
                                  onChange={(e) => setIpValue(e.target.value)}
                                  placeholder={t("Format Should Be In IP")}
                                />
                              </div>
                            </form>
                          </ModalBody>
                          <ModalFooter>
                            <Button color="primary" onClick={saveData}>
                              {t("save")}
                            </Button>
                            <Button
                              color="light"
                              className="btn btn-light"
                              onClick={() => {
                                setmodal_varying1(false);
                              }}
                            >
                              {t("Cancel")}
                            </Button>
                          </ModalFooter>
                        </Modal>

                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-xs-9">
                              <div className="table-responsive">
                                <Table>
                                  <thead>
                                    <tr className="text-center">
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        {t("IP Address")}
                                      </th>
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        {t("Token Expiry")}
                                      </th>
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        {t("Status")}
                                      </th>
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        {t("Token Expiry Date")}
                                      </th>
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        {t("Token")}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading6 ? (
                                      Array.from({
                                        length: ipList.length || 5,
                                      }).map((_, index) => (
                                        <tr key={index}>
                                          <td>
                                            <Skeleton height={30} />
                                          </td>
                                          <td>
                                            <Skeleton height={30} />
                                          </td>
                                          <td>
                                            <Skeleton height={30} />
                                          </td>
                                          <td>
                                            <Skeleton height={30} />
                                          </td>
                                          <td>
                                            <Skeleton height={30} />
                                          </td>
                                        </tr>
                                      ))
                                    ) : ipList && ipList.length > 0 ? (
                                      ipList.map(
                                        (ilist: any, index: number) => {
                                          const currentDate = new Date();
                                          const expiryDate = new Date(
                                            tokenExpDate
                                          );
                                          const isTokenExpired =
                                            expiryDate < currentDate;
                                          const status = isTokenExpired
                                            ? "Expired"
                                            : "Running";
                                          const statusClass = isTokenExpired
                                            ? "bg-danger"
                                            : "bg-success";

                                          return (
                                            <tr
                                              className="text-center"
                                              key={index}
                                            >
                                              <td
                                                style={{ whiteSpace: "nowrap" }}
                                              >
                                                {ilist}
                                              </td>
                                              <td
                                                style={{ whiteSpace: "nowrap" }}
                                              >
                                                {index === 0 &&
                                                  new Date(
                                                    tokenExpDate
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      weekday: "long",
                                                      year: "numeric",
                                                      month: "long",
                                                      day: "numeric",
                                                      hour: "2-digit",
                                                      minute: "2-digit",
                                                      second: "2-digit",
                                                    }
                                                  )}
                                              </td>
                                              <td
                                                style={{ whiteSpace: "nowrap" }}
                                              >
                                                {index === 0 && (
                                                  <span
                                                    className={`badge ${statusClass}`}
                                                  >
                                                    {t(status)}
                                                  </span>
                                                )}
                                              </td>
                                              <td
                                                style={{ whiteSpace: "nowrap" }}
                                              >
                                                {index === 0 && (
                                                  <>
                                                    <Button
                                                      color="primary"
                                                      size="sm"
                                                      onClick={() =>
                                                        setModal_standard(
                                                          !modal_standard
                                                        )
                                                      }
                                                    >
                                                      {t("Edit Date")}
                                                    </Button>
                                                    <Modal
                                                      isOpen={modal_standard}
                                                      toggle={() =>
                                                        setModal_standard(
                                                          !modal_standard
                                                        )
                                                      }
                                                    >
                                                      <ModalHeader>
                                                        <h5
                                                          className="modal-title"
                                                          id="myModalLabel"
                                                        >
                                                          {t("Edit Token Date")}
                                                        </h5>
                                                      </ModalHeader>
                                                      <ModalBody>
                                                        <Flatpickr
                                                          className="form-control border-2 dash-filter-picker shadow"
                                                          value={selectedDates}
                                                          placeholder="select Date"
                                                          onChange={
                                                            handleDateChange
                                                          }
                                                          options={{
                                                            dateFormat:
                                                              "Y-m-d H:i:s",
                                                            minDate: "today",
                                                          }}
                                                        />
                                                      </ModalBody>
                                                      <ModalFooter>
                                                        <Button
                                                          color="light"
                                                          onClick={() =>
                                                            setModal_standard(
                                                              false
                                                            )
                                                          }
                                                        >
                                                          {t("Close")}
                                                        </Button>
                                                        <Button
                                                          color="primary"
                                                          onClick={
                                                            generateTokenF
                                                          }
                                                        >
                                                          {t("Save")}
                                                        </Button>
                                                      </ModalFooter>
                                                    </Modal>
                                                  </>
                                                )}
                                              </td>
                                              <td
                                                style={{ whiteSpace: "nowrap" }}
                                              >
                                                {index === 0 && token}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <tr></tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId="7" id="animation-settings">
                        <Row>
                          <Col lg={12}>
                            {loading7 ? (
                              <Skeleton height={80} />
                            ) : (
                              <Alert
                                color="primary"
                                className="d-flex flex-column"
                              >
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                  <strong className="mb-0 mr-2">
                                    {t("Operator Name")}{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;{" "}
                                  </strong>
                                  <strong className="mb-0">
                                    {operatorName}
                                  </strong>
                                </div>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                  <strong className="mb-0 mr-2">
                                    {t("Operator Domain")} &nbsp;: &nbsp;&nbsp;{" "}
                                  </strong>
                                  <strong className="mb-0">
                                    {operatorDomain}
                                  </strong>
                                </div>
                              </Alert>
                            )}
                          </Col>
                        </Row>

                        <div className="d-flex justify-content-end align-items-center">
                          {loading7 ? (
                            <Skeleton height={30} width={150} />
                          ) : (
                            <Button
                              color="primary"
                              onClick={() => {
                                if (
                                  PermissionHelper.PermissionChecker(
                                    "operator_tenants.add_new_tenant_admin"
                                  )
                                ) {
                                  openModal();
                                } else {
                                  toast.error(
                                    "You don't have permission to add new admin"
                                  );
                                }
                              }}
                            >
                              {t("Add New Admin")}
                            </Button>
                          )}
                        </div>
                        <Modal
                          isOpen={modal_varying2}
                          toggle={() => setmodal_varying2(false)}
                          id="exampleModal"
                          modalClassName="flip"
                          centered
                        >
                          <ModalHeader>
                            {isEditModal ? "Edit Admin" : "Add New Admin"}
                            <Button
                              type="button"
                              className="btn-close justify-content-end"
                              onClick={() => setmodal_varying2(false)}
                              aria-label="Close"
                            ></Button>
                          </ModalHeader>
                          <ModalBody>
                            <div className="container-fluid">
                              <div className="row ">
                                <div className="col-md-6 col-lg-6">
                                  <div className="mb-3">
                                    <Label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      {t("First Name")}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="firstName"
                                      placeholder="Enter your firstname"
                                      value={formData.firstName}
                                      onChange={handleInputChange}
                                      id="firstNameinput"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                  <div className="mb-3">
                                    <Label
                                      for="lastNameinput"
                                      className="form-label"
                                    >
                                      {t("Last Name")}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="lastName"
                                      value={formData.lastName}
                                      onChange={handleInputChange}
                                      placeholder="Enter your lastname"
                                      id="lastNameinput"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <Label
                                      for="phonenumberInput"
                                      className="form-label"
                                    >
                                      {t("Phone Number")}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="phoneNumber"
                                      maxLength={10}
                                      value={formData.phoneNumber}
                                      onChange={handleInputChange}
                                      placeholder="+(245) 451 45123"
                                      id="phonenumberInput"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <Label
                                      for="emailidInput"
                                      className="form-label"
                                    >
                                      {t("Email Address")}
                                    </Label>
                                    <Input
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      value={formData.email}
                                      onChange={handleInputChange}
                                      placeholder="abc@gmail.com"
                                      id="emailidInput"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <Label
                                      for="passwordInput"
                                      className="form-label"
                                    >
                                      {t("Password")}
                                    </Label>
                                    <Input
                                      type="password"
                                      className="form-control"
                                      name="password"
                                      value={formData.password}
                                      onChange={handleInputChange}
                                      placeholder="Password"
                                      id="passwordInput"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mt-4">
                                    <Select
                                      value={roles}
                                      options={roles}
                                      placeholder={"Select Role"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="primary"
                              onClick={() => {
                                handleSubmit();
                                tenantamdinList();
                              }}
                            >
                              {t("Save")}
                            </Button>
                            <Button
                              color="light"
                              className="btn btn-light"
                              onClick={() => setmodal_varying2(false)}
                            >
                              {t("Cancel")}
                            </Button>
                          </ModalFooter>
                        </Modal>

                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-xs-9">
                              <div className="table-responsive">
                                <Table className="table-striped table-nowrap align-middle mb-0 mt-3">
                                  <thead>
                                    <tr className="text-center">
                                      <th scope="col">{t("S.No")}</th>
                                      <th scope="col">{t("Email")}</th>
                                      <th scope="col">{t("Full Name")} </th>
                                      <th scope="col">{t("Contact No")}</th>
                                      <th scope="col">{t("Role")}</th>
                                      <th scope="col">{t("Status")}</th>
                                      <th scope="col">{t("Action")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading7
                                      ? Array.from({
                                          length: tenantAdminList.length || 5,
                                        }).map((_, index) => (
                                          <tr key={index}>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                          </tr>
                                        ))
                                      : Array.isArray(tenantAdminList) &&
                                        tenantAdminList.map((admin, index) => (
                                          <tr
                                            key={admin.id}
                                            className="text-center"
                                          >
                                            <td>{index + 1}</td>
                                            <td>{admin.email}</td>
                                            <td>{`${admin.first_name} ${admin.last_name}`}</td>
                                            <td>{admin.phone_number}</td>
                                            <td>{admin.role}</td>
                                            <td>
                                              <span
                                                className={`rounded p-1 ${getStatusColor(
                                                  admin.status_id
                                                )} text-white`}
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  toggleStatus(admin.id)
                                                }
                                              >
                                                {getStatusText(admin.status_id)}
                                              </span>
                                            </td>
                                            <td>
                                              <FeatherIcon
                                                icon="edit-2"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => openModal(admin)}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="8" id="animation-home">
                        {loading1 ? (
                          <div>
                            <Row className="mb-3">
                              <Col lg={2}>
                                <Skeleton height={30} width={150} />
                              </Col>
                              <Col lg={4}>
                                <Skeleton height={30} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={2}>
                                <Skeleton height={30} width={150} />
                              </Col>
                              <Col lg={4}>
                                <Skeleton height={30} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={2}>
                                <Skeleton height={30} width={150} />
                              </Col>
                              <Col lg={4} md={4} xs={9}>
                                <Skeleton height={30} />
                              </Col>
                              <Col lg={3} md={2} xs={3}>
                                <Skeleton height={30} width={500} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={2}>
                                <Skeleton height={30} width={150} />
                              </Col>
                              <Col lg={4} md={4} xs={9}>
                                <Skeleton height={30} />
                              </Col>
                            </Row>
                            <div className="text-end">
                              <Skeleton height={40} width={100} />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <Card md={12} lg={12} xxl={8} className="mb-3">
                              <CardBody>
                                <div className="table-responsive mt-5">
                                  <Table className="table-striped table-nowrap align-middle mb-0">
                                    <thead>
                                      <tr className="text-center">
                                        <th scope="col">
                                          {t("Game Provider")}
                                        </th>
                                        <th scope="col">{t("API Key ")} </th>
                                        <th scope="col">{t("Secret Key ")} </th>
                                        <th scope="col">{t("Action")} </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading1 ? (
                                        Array.from({
                                          length: advaConfigData.length || 3,
                                        }).map((_, index) => (
                                          <tr
                                            key={index}
                                            className="text-center"
                                          >
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                            <td>
                                              <Skeleton height={30} />
                                            </td>
                                          </tr>
                                        ))
                                      ) : advaConfigData &&
                                        advaConfigData.length > 0 ? (
                                        advaConfigData.map((listData: any) => (
                                          <tr className="text-center">
                                            <td>{listData.vendor_name}</td>
                                            <td>
                                              {listData.api_key &&
                                              (showKeys ||
                                                selectedRow === listData.id)
                                                ? listData.api_key
                                                : listData.api_key
                                                ? "******"
                                                : "You are currently utilizing Hub Keys."}
                                            </td>
                                            <td className="">
                                              {listData.secret_key &&
                                              (showKeys ||
                                                selectedRow === listData.id)
                                                ? listData.secret_key
                                                : listData.secret_key
                                                ? "******"
                                                : "You are currently utilizing Hub Keys."}
                                            </td>

                                            <td>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                  tog_viewconfig(
                                                    listData.vendor_id,
                                                    listData.operator_id
                                                  )
                                                }
                                              >
                                                {"View"}
                                              </button>

                                              <button
                                                style={{ marginLeft: "10px" }}
                                                className="btn btn-success"
                                                onClick={() =>
                                                  tog_editconfig(
                                                    listData.vendor_id,
                                                    listData.operator_id
                                                  )
                                                }
                                              >
                                                {"Edit"}
                                              </button>

                                              <button
                                                style={{ marginLeft: "10px" }}
                                                className="btn btn-warning"
                                                onClick={() =>
                                                  resetAdvanceConfig(
                                                    listData.vendor_id,
                                                    listData.operator_id
                                                  )
                                                }
                                              >
                                                {"Reset"}
                                              </button>
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr></tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        )}
                        <Modal
                          isOpen={modal_viewconfig}
                          toggle={() => {
                            tog_viewconfig("", "");
                          }}
                          id="exampleModalView"
                          modalClassName="flip"
                          centered
                          size="lg"
                        >
                          <ModalBody>
                            <Container className="mt-3">
                              {singleAdvaConfigData &&
                              singleAdvaConfigData.length > 0 ? (
                                <Table striped bordered hover responsive>
                                  <thead>
                                    <tr>
                                      <th>Vendor Name</th>
                                      <th>API Key</th>
                                      <th>Secret Key</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {singleAdvaConfigData.map(
                                      (listData: any) => (
                                        <tr key={listData.id}>
                                          <td>{listData.vendor_name}</td>
                                          <td>
                                            {listData.api_key
                                              ? listData.api_key
                                              : "******"}
                                          </td>
                                          <td>
                                            {listData.secret_key
                                              ? listData.secret_key
                                              : "******"}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              ) : (
                                <Alert color="warning" variant="info">
                                  You are using Hub API. Please edit to set keys
                                  for operator API.
                                </Alert>
                              )}
                            </Container>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              color="danger"
                              className="btn btn-danger"
                              onClick={() => {
                                setmodal_viewconfig(false);
                              }}
                            >
                              {t("Cancel")}
                            </Button>
                          </ModalFooter>
                        </Modal>

                        <Modal
                          isOpen={modal_editconfig}
                          toggle={() => {
                            tog_editconfig("", "");
                          }}
                          id="exampleModalView"
                          modalClassName="flip"
                          centered
                        >
                          <ModalHeader>{t("Please add keys:")}</ModalHeader>
                          <ModalBody>
                            <div className="mb-3">
                              <Card md={12} lg={12} xxl={8} className="mb-3">
                                <CardBody>
                                  <Row className="mb-3">
                                    <Col lg={4}>
                                      <Label
                                        htmlFor="nameInput"
                                        className="form-label mt-2"
                                      >
                                        {t(" API Key")}
                                      </Label>
                                    </Col>
                                    <Col lg={8}>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="nameInput"
                                        value={editAPIKeyValue}
                                        onChange={(e) =>
                                          setEditAPIKeyValue(e.target.value)
                                        }
                                        placeholder={t("API Key")}
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col lg={4}>
                                      <Label
                                        htmlFor="nameInput"
                                        className="form-label mt-2"
                                      >
                                        {t("API Secret")}
                                      </Label>
                                    </Col>
                                    <Col lg={8}>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="domainInput"
                                        value={editSecretKeyValue}
                                        onChange={(e) =>
                                          setEditSecretKeyValue(e.target.value)
                                        }
                                        placeholder={t("API Secret")}
                                      />
                                    </Col>
                                  </Row>

                                  <div className="text-end">
                                    {tenentID ? (
                                      <button
                                        type="submit"
                                        className="btn btn-success me-2"
                                        onClick={() => {
                                          handleSaveEditConfig();
                                        }}
                                      >
                                        {t("Save")}
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="btn btn-success me-2"
                                        onClick={() => {
                                          handleSaveEditConfig();
                                        }}
                                      >
                                        {t("Save")}
                                      </button>
                                    )}
                                    <Button
                                      color="danger"
                                      className="btn btn-danger"
                                      onClick={() => {
                                        setmodal_editconfig(false);
                                      }}
                                    >
                                      {t("Cancel")}
                                    </Button>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </ModalBody>
                        </Modal>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Container>
        </div>
      </SkeletonTheme>
    </React.Fragment>
  );
};

export default AddOperator;
