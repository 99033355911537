import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Table, Row, Col, } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { PermissionHelper } from "Routes/helper";
import { useNavigate } from "react-router-dom";
import { fetchGameTransactionrReport, fetchGameTransactionrReportFilter, operatorsForReport, operatorsListReport, fetchGameProvider, vendorsList /*fetchItemDetails*/ } from "Services/Api";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import "../OperatorTenant/Addoperator.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

interface gameTransactionInface {
  id: number;
  game_provider_id: number;
  operator_id: number;
  username: string;
  round_id: string;
  bet_amount: number;
  win_amount: number;
  win_loss_status: string;
  bet_status: string;
  platform_txn_id: string;
  game_code: string;
  platform: string;
  game_type: string;
  selection_type: string;
  currency: string;
  game_name: string;
  game_ggr: number;
  game_provider_name: string;
  operator_name: string;
  created_at: string;
  before_balance: number;
  after_balance: number;


}


const GameTransaction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const exchangeRateEURtoINR = 87.50;
  const [playerReport, setPlayerReport] = useState<gameTransactionInface[]>([]);
  const [operatorListReports, setOperatorListReports] = useState<operatorsListReport[]>([]);
  const [vendorsListReports, setVendorsListReports] = useState<vendorsList[]>([]);
  const [username, setUsername] = useState('');
  const [roundId, setRoundId] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [internalId, setInternalId] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedValueOp, setSelectedValueOp] = useState('all');
  const [selectedValueVen, setSelectedValueVen] = useState('all');
  const today = new Date();

  const formattedToday = today.toLocaleDateString("en-GB", { day: 'numeric', month: 'short', year: 'numeric' });

  const [selectedDates, setSelectedDates] = useState([formattedToday, formattedToday]);
  document.title = t("Unified Transactions Report");
  const [selectedValue, /*setSelectedValue*/] = useState('initialValue');
  // const calculateBalance = (status: string, amount: number, betAmount: number) => {

  //   let newBalanceBefore: number = balanceBefore;
  //   let newBalanceAfter: number = balanceAfter;

  //   if (status === "win") {
  //     newBalanceBefore += betAmount;
  //     newBalanceAfter += betAmount + amount;

  //   } else if (status === "loss") {
  //     newBalanceBefore += betAmount;
  //     newBalanceAfter -= amount;
  //   }


  //   return { balanceBefore: newBalanceBefore, balanceAfter: newBalanceAfter };
  // };

  const handleSubmit = async () => {
    const selectedOp = selectedValueOp;
    const selectedVen = selectedValueVen;
    const selDates = selectedDates;
    const selDatesArray = selDates[0].split("/");

    if (selDates.length < 2) {
      toast.error("Please select date"); return false;
    }
    const arrayLength = selDatesArray.length;

    if (arrayLength <= 1) {
      toast.error("Please select date"); return false;
    }

    const convertedDates = selDates.map(date => {
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    });
    const playerReportFilterRes = await fetchGameTransactionrReportFilter(selectedOp, selectedVen, convertedDates, username, roundId, transactionId, internalId);
    if (playerReportFilterRes && playerReportFilterRes && Array.isArray(playerReportFilterRes)) {
      setPlayerReport(playerReportFilterRes);

    } else {
      console.error('Invalid data format received:', playerReportFilterRes);
    }
  }
  const calculateAmountWonLost = (winAmount: number, betAmount: number, winLossStatus: string) => {
    if (winLossStatus === 'win') {
      return winAmount - betAmount;
    } else if (winLossStatus === 'loss') {
      return -betAmount;
    } else {
      return 0;
    }
  };

  const handleChangeOp = (event: any) => {
    setSelectedValueOp(event.target.value);
  };
  const handleChangeVen = (event: any) => {
    setSelectedValueVen(event.target.value);
  };
  const handleUsernameChange = (event: any) => {
    setUsername(event.target.value);
  };
  const handleRoundId = (event: any) => {
    setRoundId(event.target.value);
  };
  const handleTransactionId = (event: any) => {
    setTransactionId(event.target.value);
  };
  const handleInternalId = (event: any) => {
    setInternalId(event.target.value);
  };
  // Usage example:

  const fetchData = async () => {
    try {
      const permission = PermissionHelper.PermissionChecker("operator_tenants.view");
      if (permission) {
        const apiResponse = await fetchGameTransactionrReport();

        if (apiResponse && apiResponse && Array.isArray(apiResponse)) {
          setPlayerReport(apiResponse);
        } else {
          console.error('Invalid data format received:', apiResponse);
        }
      } else {
        toast.error("You don't have permission to view this page");
      }
      const apiResponsesOperators = await operatorsForReport();

      if (apiResponsesOperators && apiResponsesOperators && Array.isArray(apiResponsesOperators)) {
        setOperatorListReports(apiResponsesOperators);
      } else {
        console.error('Invalid data format received:', apiResponsesOperators);
      }
      const apiResponsesVendors = await fetchGameProvider();

      if (apiResponsesVendors && apiResponsesVendors && Array.isArray(apiResponsesVendors)) {
        setVendorsListReports(apiResponsesVendors);
      } else {
        console.error('Invalid data format received:', apiResponsesVendors);
      }




    } catch (error: any) {
      console.error('Error fetching data:', error);

      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.")


      } else {

      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
  };
  return (

    <React.Fragment>
      <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
        {PermissionHelper.PermissionChecker("reports.view_game_transaction_report") && <>

          <div className="page-content">
            <Container fluid>
              <BreadCrumb title={t("Unified Transactions Report")} pageTitle={t("Reports")} />
              <div className="border border-primary p-3">
                <Row className="align-items-center mb-3">
                  <Col sm={12} md={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                    <select
                      defaultValue={selectedValueOp}
                      onChange={handleChangeOp}
                      className="form-select form-select-lg"
                      aria-label=".form-select-lg example"
                    >
                      <option value="all">{t('All Tenants')}</option>
                      {operatorListReports.map(operator => (
                        <option key={operator.id} value={operator.id}>
                          {operator.operatorname}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={12} md={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                    <select
                      defaultValue={selectedValueVen}
                      onChange={handleChangeVen}
                      className="form-select form-select-lg"
                      aria-label=".form-select-lg example"
                    >
                      <option value="all">{t('All Game Provider')}</option>
                      {vendorsListReports.map(gp => (
                        <option key={gp.id} value={gp.id}>
                          {gp.vendor_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={12} md={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                    <select defaultValue={selectedValue} className="form-select form-select-lg" aria-label=".form-select-lg example">
                      <option value="value1">{t('Currency')}</option>
                      <option value="value2">EURO</option>
                      <option value="value3">USD</option>
                      <option value="value4">AED</option>
                    </select>
                  </Col>
                  <Col sm={12} md={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                    <input
                      type="text"
                      onChange={handleUsernameChange}
                      className="form-control form-control-lg"
                      id="firstnamefloatingInput"
                      placeholder={t("Enter Username")}
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mb-3">

                  <Col sm={12} md={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                    <input
                      type="text"
                      onChange={handleRoundId}
                      className="form-control form-control-lg"
                      id="firstnamefloatingInput"
                      placeholder={t("Enter Round Id")}
                    />
                  </Col>
                  <Col sm={12} md={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                    <input
                      type="text"
                      onChange={handleTransactionId}
                      className="form-control form-control-lg"
                      id="firstnamefloatingInput"
                      placeholder={t("Enter Transaction Id")}
                    />
                  </Col> 
                  <Col sm={12} md={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                    <input
                      type="text"
                      onChange={handleInternalId}
                      className="form-control form-control-lg"
                      id="firstnamefloatingInput"
                      placeholder={t("Enter Internal Id")}
                    />
                  </Col>
                  <Col sm={12} md={3} >
                    <div className="input-group">
                      <Flatpickr
                        className="form-control form-control-lg border-0 dash-filter-picker shadow"
                        options={{
                          mode: "range",
                          dateFormat: "d M, Y",
                          defaultDate: selectedDates,
                          onChange: (selectedDates) => setSelectedDates(selectedDates.map(date => date.toLocaleDateString())),
                        }}
                      />
                      <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                    </div>
                  </Col>



                </Row>
                <Row className="align-items-center">
                  <Col sm={12} md={{ size: 4, offset: 8 }}>
                    <div className="d-flex justify-content-end gap-2">
                      <button onClick={handleSubmit} className="btn btn-primary">{t('Submit')}</button>
                      <button className="btn btn-warning">{t('Reset Filter')}</button>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="table-responsive mt-5" style={{ overflow: 'auto', maxHeight: '90vh' }}>
                <Table className="table-striped table-nowrap align-middle mb-0">
                  <thead style={{ position: 'sticky', top: '0', zIndex: '10', background: 'var(--vz-secondary-bg)' }}>
                    <tr className="text-center">

                      <th scope="col">{t('Player Username')}</th>
                      <th scope="col" >{t('Operator Name')}</th>
                      <th scope="col" >{t('Game Provider')}</th>
                      <th scope="col">{t('Round ID')}</th>
                      <th scope="col">{t('Creation Date')}</th>
                      <th scope="col">{t('Game Type')}</th>
                      <th scope="col">{t('Player Bet Status')}</th>
                      <th scope="col">{t('Amount Won/Lost')}</th>
                      <th scope="col">{t('Bet Amount')}</th>
                      <th scope="col">{t('Balance Before')}</th>
                      <th scope="col">{t('Balance After')}</th>
                      <th scope="col">{t('Trasaction ID')} </th>
                      <th scope="col">{t('Internal ID')}</th>
                      <th scope="col" >{t('Description')}</th>
                      <th scope="col">GGR-EUR</th>
                      <th scope="col">GGR-INR</th>
                      {/* <th scope="col">RTP-EUR</th> */}





                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (Array.from({ length: playerReport.length || 10 }).map((_, index) => (
                      <tr key={index} className="text-center">
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                      </tr>
                    ))
                    ) : (
                      playerReport && playerReport.length > 0 ? (
                        playerReport.map((playerRep) => {
                          const amountWonLost = calculateAmountWonLost(playerRep.win_amount, playerRep.bet_amount, playerRep.win_loss_status);
                          //const { balanceBefore, balanceAfter } = calculateBalance(playerRep.win_loss_status, playerRep.win_amount, playerRep.bet_amount);

                          return (
                            <tr className="text-center" key={playerRep.id}>
                              <td>{playerRep.username}</td>
                              <td>{playerRep.operator_name}</td>
                              <td>{playerRep.game_provider_name}</td>
                              <td>{playerRep.round_id}</td>
                              <td>
                               {formatDate(playerRep.created_at)}
                              </td>
                              <td>{playerRep.game_type}</td>
                              <td>{playerRep.win_loss_status}</td>
                              <td className={amountWonLost >= 0 ? 'text-danger' : 'text-success'}>
                                {amountWonLost > 0 ? '-' : ''}€ { Math.abs(amountWonLost).toFixed(2)}
                              </td>
                              <td>€ {playerRep.bet_amount}</td>
                              <td>{playerRep.before_balance}</td>
                              <td>{playerRep.after_balance}</td>
                              <td>{playerRep.platform_txn_id}</td>
                              <td>{playerRep.id}</td>
                              <td>{"completed"}</td>
                              <td>{playerRep.game_ggr != null ? playerRep.game_ggr : "0.00"}</td>
                              <td>{playerRep.game_ggr != null ? (playerRep.game_ggr * exchangeRateEURtoINR).toFixed(2) + " INR" : "0.00 INR"}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="text-center">
                          <td colSpan={16}><h3><b> <tr className="text-center">
                            <td colSpan={16}>No Data in this Report</td>
                          </tr></b></h3></td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>


            </Container>
          </div>
        </>}

        {!PermissionHelper.PermissionChecker("reports.view_game_transaction_report") &&
          <div className="page-content">
            <Container fluid>
              <BreadCrumb title="Player Report" pageTitle="Report" />
              <div className="text-center">
                <h4>You do not have permission to view this page</h4>
              </div>
            </Container>
          </div>
        }
      </SkeletonTheme>
    </React.Fragment>
  );
};

export default GameTransaction;
