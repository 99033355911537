import BreadCrumb from 'Components/Common/BreadCrumb';
import React, { useEffect, useState,useCallback } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import FeatherIcon from "feather-icons-react";
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, TabContent, Button, NavLink, NavItem, Nav, TabPane, Alert, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Label, Input, Table } from 'reactstrap';
import Select from 'react-select';
import classnames from "classnames";
import { createSubAdmin, fetchAdmin, fetchOperatorTenants, fetchGameProvider, Provider, addSubadminSubscription, BrandsGameList, Game, createPermission, addSubAdminGames, filteredBrands, subAdminGameList, addGgrsubadmin, addBrandsSubadmin, GGRandNGRHistorySubAdmin, HistoryRecord, editGgrandNgrSubadmin, OperatorSelected, VendorSelected,  Permissions } from 'Services/Api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const EditSubAdmin = () => {

  const { t } = useTranslation();
  const [loadingCheckboxes, setLoadingCheckboxes] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading5, setLoading5] = useState(true);
  const [password, setPassword] = useState('');
  const [/*ids*/, setId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [username, setUsername] = useState('');
  const [cost, setCost] = useState<number>();
  const [lastname, setLastname] = useState('');
  const [loading2, /*setLoading2*/] = useState(false);
  const [subAdminIds, setSubAdminIds] = useState<number>(0);
  const [colors] = useState(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark']);
  const [breadcrumbTitle, setBreadcrumbTitle] = useState('SubAdmin');
  const [operatorsListdropDown, setOperatorsListdropDown] = useState<{ label: string; value: string }[] | null>([]);
  const [/*vendorListdropDown*/, setVendorListdropDown] = useState<{ label: string; value: string }[] | null>([]);
  const [animationNavTab, setAnimationNavTab] = useState<string>("1");
  const [checkedProviders, setCheckedProviders] = useState<number[]>([]);
  const [checkboxValues, setCheckboxValues] = useState<number[]>([]);
  const [provider, setProvider] = useState<Provider[]>([]);
  const [games, setGames] = useState<Game[]>([]);
  const [brands, setBrands] = useState<any[]>([])
  const [checkedBrands, setCheckedBrands] = useState<any[]>([]);
  const [checkedBrandsBlock, setCheckedBrandsBlock] = useState<number[]>([]);
  const [searchBrandInput, setSearchBrandInput] = useState('');
  const [checkedGames, setCheckedGames] = useState<any[]>([]);
  const [checkedAllGames, setCheckedAllGames] = useState<any[]>([]);
  const [checkedAllBrands, setCheckedAllBrands] = useState<any[]>([]);
  const [checkedbBrandsVpn, setCheckedbBrandsVpn] = useState<number[]>([]);
  const [checkedbGamesBlock, setCheckedGamesBlock] = useState<number[]>([]);
  const [ggrValues, setGgrValues] = useState<{ [key: number]: string }>({});
  const [modal, setModal] = useState(false);
  const [editmodal, setEditModal] = useState(false);
  const [ngrValues, setNgrValues] = useState<{ [key: number]: string }>({});
  const [editGgrValues, setEditGgrValues] = useState<{ [key: number]: string }>({});
  const [editNgrValues, setEditNgrValues] = useState<{ [key: number]: string }>({});
  const [currentBrandId, setCurrentBrandId] = useState<any | null>(null);
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [historyData, setHistoryData] = useState<HistoryRecord[]>([]);
  const { id: subAdminID } = useParams();

  const [operatorsSelctedOpt, setOperatorsSelctedOpt] = useState<OperatorSelected[]>([]);
  const [vendorSelctedOpt, setVendorSelctedOpt] = useState<VendorSelected[]>([]);
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState<Permissions>({
    view_dashboard_reports: {
      active_operators: 0,
      best_operators: 0,
      losses_in_operators: 0,
    },
    financial_activity: {
      total_deposits: 0,
      total_withdrawals: 0,
      balance_report: 0,
    },
    gaming_activity: {
      total_bet: 0,
      total_win: 0,
      ggr_report: 0,
      most_ggr_by_player: 0,
      most_games_by_player: 0,
    },
    game_provider_dashboard: {
      most_played_game_provider: 0,
      most_profitable_game_provider: 0,
    },
    operator_tenants: {
      view: 0,
      edit: 0,
      add: 0,
      status_change: 0,
      assign_provider: 0,
      manage_brand: 0,
      manage_ggr: 0,
      token_expiry: 0,
      add_new_tenant_admin: 0,
      edit_tenant_admin: 0,
      view_tenant_admin: 0,
      manage_games: 0,
      advanceConfig: 0,
    },
    game_provider: {
      view: 0,
      edit: 0,
      status_change: 0,
    },
    reports: {
      view_game_transaction_report: 0,
      player_report: 0,

    }
  });

  // Use useCallback to memoize the fetchData function
  const fetchData = useCallback(async () => {
    try {
      const apiResponse = await fetchGameProvider();

      if (Array.isArray(apiResponse)) {
        setProvider(apiResponse);
      } else {
        console.error('Invalid API response format:', apiResponse);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.");
      } else {
        // Handle other errors here if necessary
      }
    } finally {
      setLoading3(false);
      setLoadingCheckboxes(false);
    }
  }, [navigate]); // Include 'navigate' in the dependencies of fetchData

  const operatorHandleChange = (selectedOptions: any) => {

    if (operatorsListdropDown !== null) {
      const selectAllOption = selectedOptions.find((option: any) => option.value === 0);

      if (selectAllOption) {
        const allOptionsExceptSelectAll = operatorsListdropDown
          .filter((option: any) => option.value !== 0)
          .map((option: any) => ({ value: option.value, label: option.label }));
        setOperatorsSelctedOpt(allOptionsExceptSelectAll);
        console.log("allOptionsExceptSelectAll", allOptionsExceptSelectAll);
      } else {
        setOperatorsSelctedOpt(selectedOptions);
      }
    }
  };

  const animationNavToggle = (tab: any) => {

    if (animationNavTab !== tab) {
      setAnimationNavTab(tab);
    }


  };
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    fetchData()
    const route = window.location.pathname;
    if (route.includes('edit')) {
      setBreadcrumbTitle('Edit SubAdmin');
    } else {
      setBreadcrumbTitle('Add SubAdmin');
    }
    const getOprators = async () => {

      try {
        setLoading1(true);
        const response = await fetchOperatorTenants();
        if (response.data !== undefined && response.data.length > 0) {
          let data = response.data;
          let operatorsList = data.map((item: any) => {
            return {
              value: item.id,
              label: item.operatorname
            }
          })
          operatorsList = [{ value: 0, label: 'Select All' }].concat(operatorsList);

          setOperatorsListdropDown(operatorsList);
          setLoading1(false);
        }
        const vendorresponse = await fetchGameProvider();
        if (vendorresponse !== undefined && vendorresponse.length > 0) {
          let data = vendorresponse;
          let vendorsList = data.map((item: any) => {
            return {
              value: item.id,
              label: item.vendor_name
            }
          })
          vendorsList = [{ value: 0, label: 'Select All' }].concat(vendorsList);

          setVendorListdropDown(vendorsList);
          setLoading1(false);
        }

      } catch (error) {
        console.error(error);
      }
    };
    getOprators();
    if (id) {
      const fetchSubAdmin = async () => {
        try {

          setLoading1(true);
          const response = await fetchAdmin(id ? id : "");
          const { first_name, last_name, username, ggr_cost } = response.data.adminData;
          if (response && response.data.brands && response.data.brands.length > 0) {
            const receivedBrands = response.data.brands;

            const brandIdsFromApiResponse = receivedBrands.map((brand: any) => brand.brand_id);
            setCheckedBrands(brandIdsFromApiResponse);

            const vpnEnabledBrandIds = receivedBrands
              .filter((brand: any) => brand.is_vpn_enabled === 1)
              .map((brand: any) => brand.brand_id);
            setCheckedbBrandsVpn(vpnEnabledBrandIds);

            const blockForAllOperators = receivedBrands
              .filter((brand: any) => brand.block_all_operators === 1)
              .map((brand: any) => brand.id);
            setCheckedGamesBlock(blockForAllOperators)
          }

          if (response && response.data.games && response.data.games.length > 0) {
            const receivedGames = response.data.games;
            const gameIdsFromApiResponse = receivedGames.map((games: any) => games.id);
            setCheckedGames(gameIdsFromApiResponse);

            const blockForAllSubAdmin = receivedGames
              .filter((game: any) => game.block_all_subadmin === 1)
              .map((game: any) => game.id);
            setCheckedGamesBlock(blockForAllSubAdmin);
          }
          // Handle vendor or provider data

          if (response && response.data && response.data.vendor !== null && response.data.vendor !== undefined) {
            const receivedVendors = response.data.vendor;
            // setVendors(receivedVendors);

            const filteredVendorIds = receivedVendors
              .filter((vendor: any) => vendor.vendor_status === 1)
              .map((vendor: any) => vendor.vendor_id);
            setCheckedProviders(filteredVendorIds);

            setCheckboxValues(() =>
              receivedVendors.map((vendor: any) => (vendor.is_vpn_enabled === 1 ? 1 : 0))
            );
          }
          if (response && response.data.brands_gger && response.data.brands_gger.length > 0) {
            const ggrData = response.data.brands_gger.reduce((acc: any, brand: any) => {
              acc[brand.brand_id] = brand.ggr_percent;
              return acc;
            }, {});
            setGgrValues(ggrData);
          }
          if (response && response.data.brands_gger && response.data.brands_gger.length > 0) {
            const ggrData = response.data.brands_gger.reduce((acc: any, brand: any) => {
              acc[brand.brand_id] = brand.ngr_percent;
              return acc;
            }, {});
            setNgrValues(ggrData);
          }
          const oprts = response.data.assigned_operator;
          const responses = await fetchOperatorTenants();
          if (responses.data !== undefined && responses.data.length > 0) {
            let data = responses.data;
            var operatorsLists = data.map((item: any) => {
              return {
                value: item.id,
                label: item.operatorname
              }
            })

            const selectedOperators: any[] = [];

            oprts.forEach((oprt: any) => {
              const operator = operatorsLists.find((op: any) => oprt === op.value);
              if (operator) {
                selectedOperators.push(operator);
              }
            });
            setOperatorsSelctedOpt(selectedOperators);
            setLoading1(false);
          }
          const vendorresponses = await fetchGameProvider();
          const oprt = response.data.assigned_vendor;
          if (vendorresponses !== undefined && vendorresponses.length > 0) {
            let data = vendorresponses;
            var vendorLists = data.map((item: any) => {
              return {
                value: item.id,
                label: item.vendor_name
              }
            })

            const selectedVendor: any[] = [];

            oprt.forEach((opr: any) => {
              const vendor = vendorLists.find((op: any) => opr === op.value);
              if (vendor) {
                selectedVendor.push(vendor);
              }
            });
            setVendorSelctedOpt(selectedVendor);
            setLoading1(false);
          }
          setFirstName(first_name);
          setLastname(last_name);
          setUsername(username);
          setCost(ggr_cost);
          setId(id);

          const { permission } = response.data;
          Object.keys(permission).forEach(key => {
            if (key in permissions) {
              setPermissions(prevPermissions => ({
                ...prevPermissions,
                [key]: {
                  ...prevPermissions[key as keyof typeof prevPermissions],
                  ...permission[key as keyof typeof permission]
                }
              }));
            }
          });
          setLoading1(false);
        } catch (error) {
          console.error(error);
        }
      };
      fetchSubAdmin();
    } else {
      return
    }
  }, [fetchData,id]);
  const handleCheckboxChange = (section: keyof Permissions, permission: string) => {
    setPermissions(prevPermissions => {
      const sectionPermissions = prevPermissions[section];
      if (typeof sectionPermissions === 'number') {
        return { ...prevPermissions, [section]: sectionPermissions ? 0 : 1 };
      } else {
        const updatedPermissions = { ...sectionPermissions, [permission]: sectionPermissions[permission as keyof typeof sectionPermissions] ? 0 : 1 };
        return { ...prevPermissions, [section]: updatedPermissions };
      }
    });
  };
  const handleSelectAllBrands = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked: any = e.target.checked;
    setCheckedAllBrands(isChecked);
    if (isChecked) {
      const allBrandIds = brands.map((brand) => (typeof brand === 'object' ? brand.id : null));
      setCheckedBrands(allBrandIds.filter((id) => id !== null));
    } else {
      setCheckedBrands([]);
    }
  };
  const handleSelectAllGames = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked: any = e.target.checked;
    setCheckedAllGames(isChecked);
    if (isChecked) {
      const allGameIds = games.map((game) => (typeof game === 'object' ? game.id : null));
      setCheckedGames(allGameIds.filter((id) => id !== null));
    } else {
      setCheckedGames([]);
    }
  };
  const handleCheckboxChangeBrands = (e: React.ChangeEvent<HTMLInputElement>, brandId: any) => {
    const isChecked = e.target.checked;
    setCheckedBrands((prevCheckedBrands) => {
      const updatedBrands = isChecked
        ? [...prevCheckedBrands, brandId]
        : prevCheckedBrands.filter((id) => id !== brandId);

      return updatedBrands;
    });
  };
  const handleVpnCheckboxBrands = (e: React.ChangeEvent<HTMLInputElement>, brandId: number) => {
    const isChecked = e.target.checked;

    setCheckedbBrandsVpn((prevCheckedbBrandsVpn) =>
      isChecked
        ? [...prevCheckedbBrandsVpn, brandId]
        : prevCheckedbBrandsVpn.filter((id) => id !== brandId)
    );
  };
  const handleCheckboxBlockBrands = (e: React.ChangeEvent<HTMLInputElement>, brandId: any) => {
    const isChecked = e.target.checked;

    setCheckedBrandsBlock((prevCheckedBrandBlock) => {
      const updatedBrandBlock = isChecked
        ? [...prevCheckedBrandBlock, brandId]
        : prevCheckedBrandBlock.filter((id) => id !== brandId);

      return updatedBrandBlock;
    });
  };
  const handleGamesCheckbox = (e: React.ChangeEvent<HTMLInputElement>, gameIndex: number) => {
    const isChecked = e.target.checked;

    setCheckedGames(prevCheckedGames => {
      const updatedGames = isChecked
        ? [...prevCheckedGames, gameIndex]
        : prevCheckedGames.filter(checkedGame => checkedGame !== gameIndex);

      return updatedGames;
    });
  };
  const handleCheckboxBlockGame = (e: React.ChangeEvent<HTMLInputElement>, gameId: any) => {
    const isChecked = e.target.checked;

    setCheckedGamesBlock((prevCheckedGamesBlock) => {
      const updatedGamesBlock = isChecked
        ? [...prevCheckedGamesBlock, gameId]
        : prevCheckedGamesBlock.filter((id) => id !== gameId);

      return updatedGamesBlock;
    });
  };
  const handleSubAdmin = async () => {
    const operatorIds = operatorsSelctedOpt.map((item: any) => item.value);
    const vendorIds = vendorSelctedOpt.map((item: any) => item.value);
    const permission = {
      "operatorIds": operatorIds,
      "vendorIds": vendorIds,
      "permission": permissions,
      "adminData": {
        "first_name": firstName,
        "last_name": lastname,
        "username": username,
        "password": password,
        "ggr_cost": cost

      }

    }
    try {
      const response = await createSubAdmin(permission, id ? id : "");
      if (response?.data?.id !== 'undefined') {
        console.log(response.data.id)
        setSubAdminIds(response.data.id);
        let currentUrl = window.location.href;
        let newSearch = '';
        if (currentUrl.includes('?')) {

          newSearch = "";
        } else {

          newSearch = `${currentUrl}/?id=` + response.data.id;
        }


        window.history.replaceState({}, document.title, newSearch);
      }
      if (response.status === "success") {
        if (id) {
          toast.success("Subadmin updated successfully");
        } else {
          toast.success("Subadmin created successfully");
        }
        setAnimationNavTab("2")
      } else {
        // Handle the case when the response status is not "success"
        toast.error("Failed to create or update subadmin");
      }
    } catch (error) {
      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.");
      } else {
        // Handle other errors
        toast.error("An error occurred");
      }
    }

  }
  const handlePermission = async () => {
    // let subadmin: any;
    // if (typeof subAdminID !== 'undefined' && subAdminID !== null) {
    //   subadmin = subAdminID;
    // } else {
    //   subadmin = subAdminIds;
    // }
    const permission = {
      id: subAdminID || subAdminIds,
      "permission": permissions,


    }
    try {
      const response: any = await createPermission(permission);
      if (response.status === "success") {
        if (id) {
          toast.success("Subadmin updated successfully");
        } else {
          toast.success("Subadmin created successfully");
        }
        setAnimationNavTab("3")
      } else {
        // Handle the case when the response status is not "success"
        toast.error("Failed to create or update subadmin");
      }
    } catch (error) {
      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.");
      } else {
        // Handle other errors
        toast.error("An error occurred");
      }
    }

  }
  const handleCheckboxChange2 = (event: React.ChangeEvent<HTMLInputElement>, providerId: number) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setCheckedProviders([...checkedProviders, providerId]);
    } else {
      const updatedProviders = checkedProviders.filter((id) => id !== providerId);
      setCheckedProviders(updatedProviders);
    }
  };
  const handleCheckboxChangeVPN = (index: number) => {
    setCheckboxValues((prevCheckboxValues) => {
      const updatedValues = [...prevCheckboxValues];
      updatedValues[index] = updatedValues[index] === 1 ? 0 : 1;
      return updatedValues;
    });
  };
  const handleGgrChange = (brandId: number, value: string) => {
    setGgrValues((prevValues) => ({
      ...prevValues,
      [brandId]: value,
    }));
  };
  const handleNgrChange = (brandId: number, value: string) => {
    setNgrValues((prevValues) => ({
      ...prevValues,
      [brandId]: value,
    }));
  };
  const handleEditGgrChange = (brandId: number, value: any) => {
    setEditGgrValues((prevValues) => ({
      ...prevValues,
      [brandId]: value,
    }));
  };
  const handleEditNgrChange = (brandId: number, value: any) => {
    setEditNgrValues((prevValues) => ({
      ...prevValues,
      [brandId]: value,
    }));
  };
  const handleSingleSelectChange = async (selected: { label: string; value: string }[] | null) => {

    if (selected !== null && selected.length > 0) {
      const gameProviderId = selected.map((item)=>item.value);
      try {
        setLoading3(true);

        const response = await filteredBrands(gameProviderId);

        if (response && response.data && response.status === 'success') {
          const brandsData = response.data;
          setBrands(brandsData);
        } else {
          toast.error('Failed to show brands.');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('Failed to show brands.');
      } finally {
        setLoading3(false);
      }
    }
  };
  const handleAddProvider = async () => {
    try {
      // let subadmin: any;
      // if (typeof subAdminID !== 'undefined' && subAdminID !== null) {
      //   subadmin = subAdminID;
      // } else {
      //   subadmin = subAdminIds;
      // }

      const subadmin = subAdminID ?? subAdminIds;


      const vpnConfigArray = checkedProviders.map((provider, index) => {

        const vpnConfig = {
          game_provider_id: provider,
          vpn_enable: checkboxValues[index]
        };

        return vpnConfig;
      });

      const requestData = {
        subadmin_id: subadmin,
        vendor_ids: checkedProviders,
        vpn_config: vpnConfigArray
      };

      const response = await addSubadminSubscription(requestData);
      if (response.status === 'error') {
        toast.error(response.errors, {
          autoClose: 2000,
        });
        return false;
      }

      if (response && response.status === 'success') {
        toast.success(response.message || 'Vendors subscribed successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setAnimationNavTab("4");
      } else {
        toast.error(response.errors || 'Failed to add providers.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to add providers.');
    }
  };
  const handleBrandGamesList = async () => {
    try {
      setLoading4(true);
    //  const operatorIdP = subAdminID !== undefined && subAdminID !== null ? subAdminID : subAdminIds;
      const vendorId = checkedProviders.join(",");
      const requestData = { vendor_id: vendorId };


      if (vendorId) {
        const response = await BrandsGameList(requestData);

        if (response?.status === 'success') {
          setBrands(response?.data?.brands || []);

        }
        if (response.data.brands) {
          const receivedBrands = response.data.brands
          const blockForAllOperators = receivedBrands
            .filter((game: any) => game.block_all_subadmin === 1)
            .map((game: any) => game.id);
          setCheckedBrandsBlock(blockForAllOperators);

        } else {
          const errorMessage = response?.message || 'Failed to show Games.';
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      }
    } catch (error) {
      console.error('Error:', error);

      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.")


      } else {

      }


    } finally {
      setLoading4(false);
    }
  };
  const filteredProviders = provider.filter(provider => checkedProviders.includes(provider.id));
  const brandsOptions = filteredProviders.map(provider => ({
    label: provider.vendor_name,
    value: provider.id,
  }));
  const findBrandById = (brandId: number) => {
    return brands.find((brand) => brand.id === brandId);
  };

  const handleAddBrands = async () => {
    try {
      let subadmin: any;
      if (typeof subAdminID !== 'undefined' && subAdminID !== null) {
        subadmin = subAdminID;
      } else {
        subadmin = subAdminIds;
      }
      const vpn_config_brands = {
        vpn_enable: checkedbBrandsVpn
      };
      const requestData = {
        subadmin_id: subadmin,
        brands_ids: checkedBrands,
        vpn_config_brands: vpn_config_brands,
        brand_block_arr: checkedBrandsBlock,
        vendor_ids: checkedProviders,
      };

      const response = await addBrandsSubadmin(requestData);

      if (response && response.status === 'success') {
        toast.success(response.message || 'Brands Added to Operator successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setAnimationNavTab("5");
      } else {
        toast.error('Failed to add brands to operators.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to add brands to operators.');
    }
  }
  const handleGamesList = async () => {
    try {
      setLoading5(true);
      const subadmin = subAdminID !== undefined && subAdminID !== null ? subAdminID : subAdminIds;
      const vendorId = checkedProviders.join(",");
      const requestData = { provider_ids: vendorId, subadmin_id: subadmin, brands_ids: checkedBrands };
      const response = await subAdminGameList(requestData);
      if (response?.status === 'success') {
        const gameData: any[] = response.data.map((game: Game) => ({
          id: game.id,
          name: game.name,
          brand: game.games_brands,
          block_all_operators: game.block_all_subadmin,
        }));

        setGames(gameData || []);
        gameData.forEach((name) => {

        });
        const games = response.data
        const filteredData = games.filter((item: any) => item.block_all_subadmin === 1);
        const alreadysetGames = filteredData.map((item: any) => item.id);
        setCheckedGamesBlock(alreadysetGames)

      } else {
        const errorMessage = response?.message || 'Failed to show Games.';
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }

    } catch (error) {
      console.error('Error:', error);
      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.")
      } else {
        toast.error("An error occurred");
      }

    } finally {
      setLoading5(false)
    }
  }
  const handleAddGGR = async () => {
    try {
      let subadmin: any;
      if (typeof subAdminID !== 'undefined' && subAdminID !== null) {
        subadmin = subAdminID;
      } else {
        subadmin = subAdminIds;
      }
      const checkInputs = (inputType: string, errorMessage: string) => {
        const inputs = document.querySelectorAll<HTMLInputElement>(`input[type="number"][id^="${inputType}Input"]`);
        let isEmpty = false;

        inputs.forEach(input => {
          if (input.value.trim() === '') {
            isEmpty = true;
          }
        });

        if (isEmpty) {
          toast.error(errorMessage);
        }

        return isEmpty;
      };

      if (checkInputs('ggr', 'Please Insert GGR for all brands.') || checkInputs('ngr', 'Please Insert NGR for all brands.')) {
        return;
      }

      const ggrBrandsData = brands.map((brand, index) => ({
        brand_id: brand.id,
        ggr_percent: ggrValues,
        ngr_percent: ngrValues
      }));

      const percentages: Record<string, { ggr: string[], ngr: string[] }> = {};

      ggrBrandsData.forEach(brand => {
        Object.entries(brand.ggr_percent).forEach(([brandId, ggrPercent]) => {
          if (!percentages[brandId]) {
            percentages[brandId] = { ggr: [], ngr: [] };
          }
          percentages[brandId].ggr.push(ggrPercent);
        });

        Object.entries(brand.ngr_percent).forEach(([brandId, ngrPercent]) => {
          if (!percentages[brandId]) {
            percentages[brandId] = { ggr: [], ngr: [] };
          }
          percentages[brandId].ngr.push(ngrPercent);
        });
      });

      const finalBrands = Object.entries(percentages).map(([brandId, { ggr, ngr }]) => ({
        brand_id: parseInt(brandId),
        ggr_percent: ggr[0],
        ngr_percent: ngr[0]
      }));

      const requestData = {
        subadmin_id: subadmin,
        ggr_brands: finalBrands
      };

      const response = await addGgrsubadmin(requestData);

      if (response && response.status === 'success') {
        toast.success(response.message || 'GGR Added  successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setAnimationNavTab("6");
      } else {
        toast.error('Failed to add GGR .');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to add GGR.');

    }
  }
  const handleAddGames = async () => {
    try {
      let subadmin: any;
      if (typeof subAdminID !== 'undefined' && subAdminID !== null) {
        subadmin = subAdminID;
      } else {
        subadmin = subAdminIds;
      }
      const requestData = {
        subadmin_id: subadmin,
        games_ids: checkedGames,
        games_block_arr: checkedbGamesBlock
      };
      const response = await addSubAdminGames(requestData);

      if (response && response.status === 'success') {
        toast.success(response.message || 'Games Added to Operator successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setAnimationNavTab("6");
      } else {
        toast.error(response.message || 'Failed to add games to operators.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to add games to operators.');
    }
  }
  const toggleGGRandNGR = () => setModal(!modal);

  const fetchGGRandNgrHistory = async (brandId: any,) => {
    try {
      let subadmin: any;
      if (typeof subAdminID !== 'undefined' && subAdminID !== null) {
        subadmin = subAdminID;
      } else {
        subadmin = subAdminIds;
      }
      setLoading(true);
      const response = await GGRandNGRHistorySubAdmin(brandId, subadmin);
      setHistoryData(response.data);
      toggleGGRandNGR();
    } catch (error) {
      toast.error("Failed to fetch history data");
    } finally {
      setLoading(false);
      toggleGGRandNGR();
    }
  };
  const formatDate = (dateString: string, formatString: string): string => {
    const date = new Date(dateString);
    return format(date, formatString);
  };

  const toggleEditGgrandNgr = (brandId = null) => {
    if (brandId !== null) {
      setCurrentBrandId(brandId);
    }
    setEditModal(!editmodal);
  };

  const saveEdits = async (brandId: number, date: string, existingGgrPercent: string, existingNgrPercent: string) => {
    try {
      let subadmin: any;
      if (typeof subAdminID !== 'undefined' && subAdminID !== null) {
        subadmin = subAdminID;
      } else {
        subadmin = subAdminIds;
      }
      setLoading(true);
      const formattedDate = `${date}-02`;
      const ggrPercent = editGgrValues[brandId] !== undefined ? editGgrValues[brandId] : existingGgrPercent;
      const ngrPercent = editNgrValues[brandId] !== undefined ? editNgrValues[brandId] : existingNgrPercent;
      const payload = {
        subadmin_id: subadmin,
        date: formattedDate,
        brand_id: brandId,
        ggr_percent: { [currentBrandId]: Number(ggrPercent) },
        ngr_percent: { [currentBrandId]: Number(ngrPercent) },

      }
      const response = await editGgrandNgrSubadmin(payload);

      if (response.status === "success") {
        toast.success(response.message)
        fetchGGRandNgrHistory(brandId)

      } else {
        toast.error('Failed to update user providers:', response.message);
      }

      toggleEditGgrandNgr();
    } catch (error) {
      toast.error("Failed to fetch history data");
    } finally {
      setLoading(false);
      toggleEditGgrandNgr();
    }
  };



  return (
    <React.Fragment>
      <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t(breadcrumbTitle)} pageTitle={t("Subadmin")} />
            <div>
              <Row className="align-items-center">
                <Col lg={12}>
                  <div className="d-flex justify-content-end align-item-center">
                    <button className="btn btn-primary" onClick={() => navigate(-1)}>
                      <i className="ri-arrow-left-line"></i>{t('Back')}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="d-flex align-items-end justify-content-center mt-3">
              <Col xs={12} md={12} lg={12} xxl={12} >
                <Card md={12} lg={12} xxl={8}>
                  <CardBody>
                    <Nav md={12} lg={12} pills className="nav nav-pills animation-nav nav-justified gap-2 mb-3 text-body">
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" , whiteSpace:'nowrap' }} className={classnames({ active: animationNavTab === "1", })} onClick={() => { animationNavToggle("1"); }} >
                          {t('Details')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" , whiteSpace:'nowrap' }} className={classnames({ active: animationNavTab === "2", })} onClick={() => { animationNavToggle("2"); }} >
                          {t('Permissions')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" , whiteSpace:'nowrap' }} className={classnames({ active: animationNavTab === "3", })} onClick={() => { animationNavToggle("3"); }} >
                          {t('Manage Providers')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" , whiteSpace:'nowrap'}} className={classnames({ active: animationNavTab === "4", })} onClick={() => { animationNavToggle("4"); handleBrandGamesList(); }} >
                          {t('Manage Brands')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" , whiteSpace:'nowrap' }} className={classnames({ active: animationNavTab === "5", })} onClick={() => { animationNavToggle("5"); handleBrandGamesList(); }} >
                          {t('Manage GGR')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" , whiteSpace:'nowrap'}} className={classnames({ active: animationNavTab === "6", })} onClick={() => { animationNavToggle("6"); handleBrandGamesList(); handleGamesList(); }} >
                          {t('Manage Games')}
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={animationNavTab}>
                      <TabPane tabId="1">
                        {loading1 || loading2 ? (
                          <div>
                            <Row className="mb-3">
                              <Col lg={6}>
                                <Skeleton height={30} />
                              </Col>
                              <Col lg={6}>
                                <Skeleton height={30} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={6}>
                                <Skeleton height={30} />
                              </Col>
                              <Col lg={6}>
                                <Skeleton height={30} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={6}>
                                <Skeleton height={30} />
                              </Col>
                              <Col lg={6}>
                                <Skeleton height={30} />
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col lg={6}>
                                <Skeleton height={30} />
                              </Col>
                            </Row>
                            <div className="text-end">
                              <Skeleton height={40} width={100} />
                            </div>
                          </div>
                        ) : (
                          <div style={{ marginTop: "20px" }}>
                            <Card md={12} lg={12} xxl={8} className="mb-3">
                              <CardBody>
                                <Row className="mb-3">
                                  <Col lg={6}>
                                    <Label htmlFor="firstNameInput" className="form-label mt-2">
                                      {t('First Name')}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="firstNameInput"
                                      value={firstName}
                                      onChange={(e) => setFirstName(e.target.value)}
                                      placeholder="First Name"
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <Label htmlFor="lastNameInput" className="form-label mt-2">
                                      {t('Last Name')}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="lastNameInput"
                                      onChange={(e) => setLastname(e.target.value)}
                                      value={lastname}
                                      placeholder="Last Name"
                                    />
                                  </Col>
                                </Row>

                                <Row className="mb-3">
                                  <Col lg={6}>
                                    <Label htmlFor="usernameInput" className="form-label mt-2">
                                      {t('Email')}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="usernameInput"
                                      onChange={(e) => setUsername(e.target.value)}
                                      value={username}
                                      placeholder="Username"
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <Label htmlFor="passwordInput" className="form-label mt-2">
                                      {t('Password')}
                                    </Label>
                                    <Input
                                      type="password"
                                      className="form-control"
                                      id="setPasswordInput"
                                      onChange={(e) => setPassword(e.target.value)}
                                      value={password}
                                      placeholder="Password"
                                    />
                                  </Col>

                                </Row>
                                <Row className="mb-3">
                                  <Col lg={6}>
                                    <Label htmlFor="operatorHtml" className="form-label mt-2">
                                      {t('Select Operators (Assign to Subadmin)')}
                                    </Label>
                                    <Select
                                      onChange={operatorHandleChange}
                                      value={operatorsSelctedOpt}
                                      options={operatorsListdropDown}
                                      placeholder={"Select"}
                                      isSearchable={true}
                                      isMulti={true}
                                    />
                                  </Col>
                                </Row>
                                <div className="text-end">
                                  <button type="submit" onClick={handleSubAdmin} className="btn btn-primary">
                                    {t("Save")}
                                  </button>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        )}
                      </TabPane>


                      <TabPane tabId="2">
                        <Row>
                          <Col lg={12}>
                            <Alert color="primary" className="d-flex flex-column">
                              <div className="d-flex justify-content-start align-items-center mb-2">
                                <strong className="mb-0 mr-2">{t('Subadmin Name')}   &nbsp;&nbsp; : &nbsp; </strong>
                                <strong className="mb-0">{firstName}</strong>
                              </div>
                              <div className="d-flex justify-content-start align-items-center mt-2">
                                <strong className="mb-0 mr-2">{t('Subadmin Email')} &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; </strong>
                                <strong className="mb-0">{username}</strong>
                              </div>
                            </Alert>
                          </Col>
                        </Row>

                        <div>
                          {(Object.keys(permissions) as Array<keyof typeof permissions>).map((section: keyof typeof permissions, index) => (
                            <div key={`section${index}`} style={{ marginTop: 40 }}>
                              <h2 style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px', marginLeft: "30px" }}>
                                {t(section.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))}
                              </h2>
                              <Table>
                                <tbody>
                                  {(Object.keys(permissions[section]) as Array<keyof typeof permissions[typeof section]>).map((permission: keyof typeof permissions[typeof section], index) => (
                                    <tr key={`permission${index}`}>
                                      <td>
                                        <div className={`text2 form-check form-check-${colors[index % colors.length]}`}>
                                          <div className="manage-input-main2">
                                            <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`${section}${permission}Checkbox`}
                                              checked={permissions[section][permission] === 1}
                                              onChange={() => handleCheckboxChange(section, permission)}
                                            />
                                            &nbsp;&nbsp;
                                            <Label className="form-check-label" htmlFor={`${section}${permission}Checkbox`} style={{ display: 'flex', alignItems: 'center' }}>
                                              {
                                                (() => {
                                                  if (permission === 'view_game_transaction_report') {
                                                    return t('Unified Transactions Report');
                                                  }
                                                  return <span>{t(permission as string).replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>;
                                                })()
                                              }
                                            </Label>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          ))}
                        </div>
                        <div className="text-end">
                          <button type="submit" onClick={handlePermission} className="btn btn-primary">
                            {t("Save")}
                          </button>
                        </div>
                      </TabPane>

                      <TabPane tabId="3">
                        <Row>
                          <Col lg={12}>
                            {loading3 || loadingCheckboxes ? (

                              <Skeleton height={80} />
                            ) : (

                              <Alert color="primary" className="d-flex flex-column">
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                  <strong className="mb-0 mr-2">{t('Subadmin Name')}   &nbsp;&nbsp; : &nbsp; </strong>
                                  <strong className="mb-0">{firstName}</strong>
                                </div>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                  <strong className="mb-0 mr-2">{t('Subadmin Email')} &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; </strong>
                                  <strong className="mb-0">{username}</strong>
                                </div>
                              </Alert>
                            )}
                          </Col>
                        </Row>
                        <Row className="align-items-center border-dark mt-5">
                          <Col xs={12} sm={4} lg={4} md={4} className="align-items-end">
                            <strong>{t('Add Game Provider')}</strong>
                          </Col>
                          <div>
                            <Table>
                              <thead>
                                <tr className="text-center">
                                  <th>{t('Game Provider')}</th>
                                  <th>{t('Enable VPN')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loading3 || loadingCheckboxes ? (

                                  Array.from({ length: provider.length || 5 }).map((_, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                    </tr>
                                  ))
                                ) : (

                                  provider && provider.length > 0 ? (
                                    provider.map((provider, index) => (
                                      <tr className="hover-effect" key={index}>
                                        <td>
                                          <div className={`text-center form-check form-check-${colors[index % colors.length]}`} style={{ display: 'flex', justifyContent: 'right' }}>
                                            <div className="manage-input-main">
                                              <div className="manage-input">
                                                <input
                                                  value={provider.id}
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id={`formCheck${index}`}
                                                  onChange={(e) => handleCheckboxChange2(e, provider.id)}
                                                  checked={checkedProviders.includes(provider.id)}
                                                />
                                                &nbsp;
                                                <label className="form-check-label" htmlFor={`formCheck${index}`}>
                                                  {provider.vendor_name}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={`text-center form-check form-check-${colors[index % colors.length]}`}>
                                            <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`vpnCheckboxProvider${index}`}
                                              onChange={() => handleCheckboxChangeVPN(index)}
                                              checked={checkboxValues[index] === 1}
                                            />
                                            &nbsp;&nbsp;
                                            <Label className="form-check-label" htmlFor={`vpnCheckboxProvider${index}`}>
                                              {t('Enable VPN')}
                                            </Label>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td>{t('No Game Providers Available')}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Row>

                        <div className="text-end mt-5">
                          {subAdminID ? (
                            <button className="btn btn-primary" onClick={() => { handleAddProvider(); handleBrandGamesList(); }}>
                              {t('Save')}
                            </button>
                          ) : (
                            <button className="btn btn-primary" onClick={() => { handleAddProvider(); handleBrandGamesList(); }}>
                              {t('Save')}
                            </button>
                          )}
                        </div>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col lg={12}>
                            {loading4 || loadingCheckboxes ? (

                              <Skeleton height={80} />
                            ) : (

                              <Alert color="primary" className="d-flex flex-column">
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                  <strong className="mb-0 mr-2">{t('Subadmin Name')}   &nbsp;&nbsp; : &nbsp; </strong>
                                  <strong className="mb-0">{firstName}</strong>
                                </div>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                  <strong className="mb-0 mr-2">{t('Subadmin Email')} &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; </strong>
                                  <strong className="mb-0">{username}</strong>
                                </div>
                              </Alert>
                            )}
                          </Col>
                        </Row>

                        <Row className="align-items-center mt-3">
                          <Col xs={12} sm={4} lg={4} md={4} className=" align-items-end">
                            <strong>{t('Selected Game Provider')}</strong>
                          </Col>
                          <Col xs={12} sm={4} lg={3} md={4} className="align-items-end mt-3 mb-3 mt-xl-0 mb-xl-0">
                            <Select
                              onChange={handleSingleSelectChange}
                              options={brandsOptions}
                              placeholder={t("Select Provider")}
                              isSearchable={true}
                              isMulti={true}
                            />
                          </Col>
                          <Col xs={12} sm={4} lg={3} md={4} className="align-items-end mt-3 mb-3 mt-xl-0 mb-xl-0" >
                            <Input
                              type="text"
                              value={searchBrandInput}
                              onChange={e => setSearchBrandInput(e.target.value)}
                              placeholder={t("Search Brand")}
                            />
                          </Col>
                        </Row>
                        <Row className="align-items-center border-dark mt-3">
                          <Col xs={12} sm={4} lg={4} md={4} className="align-items-end">
                            <strong>{t('Select a Brand')} </strong>
                          </Col>
                          <div className='table-responsive'>
                            <Table>
                              <thead>
                                <tr className="text-center">
                                  <th>{t('Brand')}</th>
                                  <th style={{ whiteSpace: "nowrap" }}>{t('Enable VPN')}</th>
                                  <th style={{ whiteSpace: "nowrap" }}>{t('Block For All Subadmins')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="text2"  >
                                      <div className=" manage-input-main" >
                                        <Input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="selectAllGames"
                                          value={checkedAllBrands}
                                          onChange={handleSelectAllBrands}
                                        />
                                        &nbsp; &nbsp;
                                        <Label className="form-check-label" htmlFor="selectAllGames">
                                          <h5>
                                            <strong style={{ whiteSpace: "nowrap" }}>
                                              {t('SELECT ALL Brands')}
                                            </strong>
                                          </h5>
                                        </Label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {loading4 ? (
                                  Array.from({ length: brands.length || 5 }).map((_, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  brands && brands.length > 0 ? (
                                    brands.filter(brand => brand.brand_name.toLowerCase().includes(searchBrandInput.toLowerCase())).map((brand, index) => (
                                      <tr className="hover-effect "
                                        key={index}>
                                        <td >
                                          <div className={`text-center form-check form-check-${colors[index % colors.length]}`} style={{ display: 'flex', justifyContent: 'right' }}>
                                            <div className="manage-input-main">
                                              <div className="manage-input">
                                                <input
                                                  value={brand.id}
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id={`brandCheck${index}`}
                                                  onChange={(e) => handleCheckboxChangeBrands(e, brand.id)}
                                                  checked={checkedBrands.includes(brand.id)}
                                                /> &nbsp;
                                                <label className="form-check-label" htmlFor={`brandCheck${index}`}>
                                                  {brand.brand_name}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td >
                                          <div className={`text-center form-check form-check-${colors[index % colors.length]}`}>
                                            <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`vpnCheckbox${index}`}
                                              onChange={(e) => handleVpnCheckboxBrands(e, brand.id)}
                                              checked={checkedbBrandsVpn.includes(brand.id)}
                                            />
                                            &nbsp;&nbsp;
                                            <Label className="form-check-label" htmlFor={`vpnCheckbox${index}`} >
                                              Enable VPN
                                            </Label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={`text-center form-check form-check-${colors[index % colors.length]}`}>
                                            <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`gameCheckBlock${index}`}
                                              value={checkedBrandsBlock.join(',')}
                                              onChange={(e) => handleCheckboxBlockBrands(e, brand.id)}
                                              checked={checkedBrandsBlock.includes(brand.id)}
                                            />
                                            &nbsp;&nbsp;
                                            <Label className="form-check-label" htmlFor={`gameCheckBlock${index}`}>
                                              Block for All Subadmins
                                            </Label>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={3} className="text-center">
                                        <strong>{t('No Brands Available')}</strong>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Row>

                        <div className="text-end mt-5">
                          {subAdminID ? (
                            <button className="btn btn-primary" onClick={() => { handleAddBrands(); handleGamesList() }}>
                              {t('Save')}
                            </button>
                          ) : (
                            <button className="btn btn-primary" onClick={() => { handleAddBrands(); handleGamesList() }}>
                              {t('Save')}
                            </button>
                          )}
                        </div>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col lg={12}>
                            <Alert color="primary" className="d-flex flex-column">
                              <div className="d-flex justify-content-start align-items-center mb-2">
                                <strong className="mb-0 mr-2">{t('Subadmin Name')}   &nbsp;&nbsp; : &nbsp; </strong>
                                <strong className="mb-0">{firstName}</strong>
                              </div>
                              <div className="d-flex justify-content-start align-items-center mt-2">
                                <strong className="mb-0 mr-2">{t('Subadmin Email')} &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; </strong>
                                <strong className="mb-0">{username}</strong>
                              </div>
                            </Alert>
                          </Col>
                        </Row>
                        <div>
                          {checkedBrands.map((brandId, index) => {
                            const brand = findBrandById(brandId);

                            if (!brand) {

                              return null;
                            }

                            return (
                              <Row key={brand.id} className="mb-3">
                                <Col lg={2}>
                                  <Label htmlFor={`ggrInput${brand.id}`} className="form-label mt-2">
                                    {t('Select GGR for')} {brand.brand_name}
                                  </Label>
                                </Col>
                                <Col lg={2} md={4} xs={9}>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    value={ggrValues[brand.id]}
                                    id={`ggrInput${brand.id}`}
                                    onChange={(e) => {
                                      const value: any = Number(e.target.value);
                                      if (value <= 100) {
                                        handleGgrChange(brand.id, value);
                                      } else {
                                        toast.error("GGR cannot be more than 100");
                                      }
                                    }}
                                    placeholder="GGR"
                                  />
                                </Col>
                                <Col lg={1} md={2} xs={3}>
                                  <Label htmlFor={`ggrInput${brand.id}`} className="form-label mt-2">
                                    <strong>% </strong>
                                  </Label>
                                </Col>
                                <Col lg={2} md={1} xs={2}>
                                  <Button color="link" onClick={() => { setCurrentBrandId(brand.id); fetchGGRandNgrHistory(brand.id); }} >
                                    <FeatherIcon icon="eye" />
                                  </Button>
                                </Col>


                                <Col lg={2}>
                                  <Label htmlFor={`ngrInput${brand.id}`} className="form-label mt-2">
                                    {('Select NGR for')} {brand.brand_name}
                                  </Label>
                                </Col>
                                <Col lg={2} md={4} xs={9}>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    value={ngrValues[brand.id]}
                                    id={`ngrInput${brand.id}`}
                                    onChange={(e) => {
                                      const value: any = Number(e.target.value);
                                      if (value <= 100) {
                                        handleNgrChange(brand.id, value);
                                      } else {
                                        toast.error("NGR cannot be more than 100");
                                      }
                                    }}
                                    placeholder="NGR"
                                  />
                                </Col>
                                <Col lg={1} md={2} xs={3}>
                                  <Label htmlFor={`ngrInput${brand.id}`} className="form-label mt-2">
                                    <strong>% </strong>
                                  </Label>
                                </Col>

                              </Row>
                            );
                          })}
                        </div>
                        <div className="text-end">
                          {subAdminID ? (
                            <button type="submit" className="btn btn-primary" onClick={() => { handleAddGGR(); handleGamesList(); }}>
                              {t('Save')}
                            </button>
                          ) : (
                            <button type="submit" className="btn btn-primary" onClick={() => { handleAddGGR(); handleGamesList(); }}>
                              {t('Save')}
                            </button>
                          )}
                        </div>



                        <Modal isOpen={modal} toggle={toggleGGRandNGR} size="lg">
                          <ModalHeader toggle={toggleGGRandNGR}>Brands History</ModalHeader>
                          <ModalBody>
                            {loading ? (
                              <p>Loading...</p>
                            ) : (
                              <div>
                                <h5>GGR & NGR History For {findBrandById(historyData[0]?.brand_id)?.brand_name}</h5>
                                {historyData && historyData.length ? (
                                  <div className="table-responsive">
                                    <Table>
                                      <thead>
                                        <tr className="text-center">
                                          <th>Month/Year</th>
                                          <th>GGR %</th>
                                          <th>NGR %</th>
                                          <th>Updated At</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {historyData.map((record) => (
                                          <tr key={record.id} className="text-center">
                                            <td className="col-2">{formatDate(record.month_year, 'MMMM yyyy')}</td>
                                            <td className="col-2">
                                              {editingRow === record.id ? (
                                                <Input
                                                  type="number"
                                                  className="form-control"
                                                  value={editGgrValues[currentBrandId] !== undefined ? editGgrValues[currentBrandId] : record.ggr_percent}
                                                  onChange={(e) => handleEditGgrChange(currentBrandId, Number(e.target.value))}
                                                />
                                              ) : (
                                                `${record.ggr_percent} %`
                                              )}
                                            </td>
                                            <td className="col-2">
                                              {editingRow === record.id ? (
                                                <Input
                                                  type="number"
                                                  className="form-control"
                                                  value={editNgrValues[currentBrandId] !== undefined ? editNgrValues[currentBrandId] : record.ngr_percent}
                                                  onChange={(e) => handleEditNgrChange(currentBrandId, Number(e.target.value))}
                                                />
                                              ) : (
                                                `${record.ngr_percent} %`
                                              )}
                                            </td>
                                            <td className="col-3">{formatDate(record.updated_at, 'MMMM dd, yyyy HH:mm')}</td>
                                            <td className="col">
                                              {editingRow === record.id ? (
                                                <Button color="primary" onClick={() => saveEdits(currentBrandId, record.month_year, record.ggr_percent, record.ngr_percent)}>Save</Button>
                                              ) : (
                                                <Button color="link" onClick={() => setEditingRow(record.id)}>
                                                  <FeatherIcon icon="edit-2" />
                                                </Button>
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </div>
                                ) : (
                                  <p>No data available</p>
                                )}
                              </div>
                            )}
                          </ModalBody>
                        </Modal>

                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col lg={12}>
                            {loading5 || loadingCheckboxes ? (

                              <Skeleton height={80} />
                            ) : (

                              <Alert color="primary" className="d-flex flex-column">
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                  <strong className="mb-0 mr-2">{t('Subadmin Name')}   &nbsp;&nbsp; : &nbsp; </strong>
                                  <strong className="mb-0">{firstName}</strong>
                                </div>
                                <div className="d-flex justify-content-start align-items-center mt-2">
                                  <strong className="mb-0 mr-2">{t('Subadmin Email')} &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; </strong>
                                  <strong className="mb-0">{username}</strong>
                                </div>
                              </Alert>
                            )}
                          </Col>
                        </Row>

                        <Row className="align-items-center border-dark mt-3">
                          <div className='table-responsive'>
                            <Table>
                              <thead>
                                <tr className="text-center">
                                  <th style={{whiteSpace:'nowrap'}}>{t('Select the Games you Want to show')}</th>
                                  <th>{t('Brands')}</th>
                                  <th style={{whiteSpace:'nowrap'}}>{t('Block For All Subadmins')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="text2">
                                      <div className=" manage-input-main" >
                                        <Input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="selectAllGames"
                                          value={checkedAllGames}
                                          onChange={handleSelectAllGames}
                                        />
                                        &nbsp; &nbsp;
                                        <Label className="form-check-label" htmlFor="selectAllGames">
                                          <h5>
                                            <strong style={{whiteSpace:'nowrap'}}>
                                              {t('SELECT ALL GAMES')}
                                            </strong>
                                          </h5>
                                        </Label>

                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                {loading5 ? (
                                  Array.from({ length: games.length || 5 }).map((_, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                      <td>
                                        <Skeleton height={30} />
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  games && games.length > 0 ? (
                                    games.map((game, index) => (
                                      <tr className="hover-effect"
                                        key={index}>
                                        <td>
                                          <div className={`text2 form-check form-check-${colors[index % colors.length]}`}>
                                            <div className="manage-input-main">
                                              <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`gameCheck${index}`}
                                                value={checkedGames}
                                                onChange={(e) => handleGamesCheckbox(e, game.id)}
                                                checked={checkedGames.includes(game.id)}
                                              />
                                              &nbsp;&nbsp;
                                              <Label className="form-check-label" htmlFor={`gameCheck${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{game.name}</span>
                                              </Label>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={`text-center form-check form-check-${colors[index % colors.length]}`}>
                                            <Label>
                                              <span>{game.brand}</span>
                                            </Label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={`text-center form-check form-check-${colors[index % colors.length]}`}>

                                            <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`gameCheckBlock${index}`}
                                              value={checkedbGamesBlock.join(',')}
                                              onChange={(e) => handleCheckboxBlockGame(e, game.id)}
                                              checked={checkedbGamesBlock.includes(game.id)}
                                            />
                                            &nbsp;&nbsp;
                                            <Label className="form-check-label" htmlFor={`gameCheckBlock${index}`}>
                                              Block for All Subadmins
                                            </Label>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={3} className="text-center"><strong>
                                        {t('No Games Available')}
                                      </strong></td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Row>

                        <div className="text-end mt-5">
                          {subAdminID ? (
                            <button className="btn btn-primary" onClick={() => { handleAddGames(); }}>
                              {t('Save')}
                            </button>
                          ) : (
                            <button className="btn btn-primary" onClick={() => { handleAddGames(); }}>
                              {t('Save')}
                            </button>
                          )}
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>


            </div>
          </Container>
        </div>
      </SkeletonTheme >
    </React.Fragment >
  );
};

export default EditSubAdmin;